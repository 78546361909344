import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { listSettings } from '../../settings/dashboard.settings';
import { FormGroup, FormControl,Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styles: []
})
export class CreateComponent implements OnInit {
  slug: string;
  settings: any;
  formData: any = [];
  formGroup:FormGroup;
  submitted = false;
  fileToUpload: File = null;
  selectedField: any;
  uploadType = 'single';
  modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote', 'code-block'],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      ['clean'],
    ]
  };
  answerArray = ['A','B','C','D','E'];
  answers = [
    {correct: false, choice: 'A', answer: '', question: ''},
    {correct: false, choice: 'B', answer: '', question: ''},
    {correct: false, choice: 'C', answer: '', question: ''},
    {correct: false, choice: 'D', answer: '', question: ''},
    {correct: false, choice: 'E', answer: '', question: ''}
  ];
  childQuestions = [];
  id = '';
  parentId = '';
  rediretToProduct = false;
  quizCount = false;
  constructor(private route: ActivatedRoute, private api: ApiService, private router: Router) {

  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.id = '';
      this.settings = {};
      this.slug = params["slug"];
      this.parentId = params["id"];
      if(listSettings[this.slug]) {
        this.settings = listSettings[this.slug];
        this.formData = this.settings?.model;
        this.populateRefFields();
        this.generateForm();
        if(this.formGroup.get("qtype")) {
          this.formGroup.get("qtype").valueChanges.subscribe(data => {
            if(data === 'parent' || data === '') {
              this.removeAnswers();
            } else {
              this.populateAnswers();
            }
          });
        }
      }
      if (this.slug === 'product') {
        const quizId = this.route.snapshot.queryParamMap.get('quizId');
        const productType = this.route.snapshot.queryParamMap.get('productType');
        const name = this.route.snapshot.queryParamMap.get('name');
        if (quizId) {
          this.formGroup.get('quizId').patchValue(parseInt(quizId, 10));
          this.formGroup.get('productType').patchValue(productType);
          this.formGroup.get('name').patchValue(name);
        }
      }
    })
  }

  generateForm() {
    if(this.formData) {
      let group={};
      this.formData.forEach( input =>{
        if(input.type === "ref") {
          if(input.name === 'subject') {
            group['parentSubject'] = new FormControl('');
          }
          input.options = [];
          this.getDataFromAPI(input.endpoint).subscribe(data => {
            input.options = data;
          });
        }
        const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        let validations = [];
        if(input.required !== false) {
          validations.push(Validators.required);
        }
        if(input.validateEmail) {
          validations.push(Validators.pattern(EMAIL_REGEX));
        }
        if(input.min) {
          validations.push(Validators.min(input.min));
        }
        if(input.max) {
          validations.push(Validators.max(input.max));
        }
        group[input.name] = new FormControl('', validations);
      })
      this.formGroup = new FormGroup(group);
    } else {
      let group={};
      this.formData = [];
      this.formGroup = new FormGroup(group);

    }
  }

  populateAnswers() {
    this.answers.forEach( (field) => {
      let validations = [];
      validations.push(Validators.required);
      this.formGroup.addControl(field.choice, new FormControl('', validations))
    })
  }

  removeAnswers() {
    this.answers.forEach( (field) => {
      let validations = [];
      validations.push(Validators.required);
      this.formGroup.removeControl(field.choice)
    })
  }

  radioChange(event) {
    this.answers.forEach( (item, index) => {
      if(item.choice === event.value) {
        item.correct = true;
      } else {
        item.correct = false;
      }
    })
  }

  populateRefFields() {
    this.formData.forEach( (field) => {
      if(field?.type === 'ref') {
        this.api.get(field?.endpoint).subscribe(data => {
          field.options = data;
        }, err => {
          console.log(err);
        })
      }
    })
  }
  onSubmit() {
    if (this.slug === 'product') {
      if (this.formGroup.value.publicationId && this.formGroup.value.serviceId) {
        return alert('Hem Yayın hem Servis aynı anda seçili olamaz!');
      }
    }
    if (this.slug === 'user') {
      if (this.formGroup.value.type) {
        this.formGroup.value.type = parseInt(this.formGroup.value.type);
      }
    }
    if(!this.formGroup.value.timeOpen) delete this.formGroup.value.timeOpen;
    if(!this.formGroup.value.timeCLose) delete this.formGroup.value.timeClose;
    if(!this.formGroup.value.resultTime) delete this.formGroup.value.resultTime;
    if(!this.formGroup.value.publicationId) delete this.formGroup.value.publicationId;
    if(!this.formGroup.value.quizId) delete this.formGroup.value.quizId;
    if(!this.formGroup.value.isActive) delete this.formGroup.value.isActive;
    if(!this.formGroup.value.isPremium) {
      this.formGroup.value.isPremium = false;
    }
    if(this.slug === 'publication') {
      if(!this.formGroup.value.isPremium) {
        this.formGroup.value.isPremium = false;
      }
    }
    if(this.formGroup.valid) {
      let url = '/api/v1/' + this.slug;
      if(this.slug === 'user') {
        url = '/api/v1/user/signup';
      } else {
        url = `/api/v1/${this.slug}/add`;
      }
      if(this.slug === 'quiz' && !this.formGroup.value.isOpen) {
        this.formGroup.value.isOpen = false;
      }
      if(this.formGroup.value.qtype === 'multichoice') {
        this.validateAndSaveQuestion();
      } else {
        let body = {};
        let object = this.formGroup.value;
        Object.keys(object).map(function(objectKey, index) { // Get only values that changed
          if( object[objectKey] !== '' ) {
            body[objectKey] = object[objectKey];
          }
        });
        console.log(body);
        this.api.post(url, body).subscribe(data => {
            alert('Kayıt Oluşturuldu');
            if(this.formGroup.value.redirectToProduct) {
              this.router.navigate(['create/product/'], {queryParams: {quizId: data.id, productType: 'quiz', name: data.name}});
            } else {
              this.router.navigate(['edit/' + this.slug + '/' + data.id]);
            }
        }, err => {
          alert("Hata! Formu Kontrol Edin!");
          console.log(err);
        })
      }

    }
  }

  onChange() {
    console.log(this.formGroup);
  }

  dateChange(event, field: any) {
    this.formGroup.patchValue({[field.name]: event.value.utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")})
  }

  validateAndSaveQuestion() {
    let url = `/api/v1/${this.slug}/add`;
    let validAnswers = false;
        this.answers.forEach( (item, index) => {
          item.answer = this.formGroup.value[this.answerArray[index]];
          if(item.correct) {
            validAnswers = true;
          }
        })
        if(validAnswers) {
          if(this.parentId) {
            this.formGroup.value.parent = parseInt(this.parentId);
          }
          this.api.post(url, this.formGroup.value).subscribe(data => {
            this.id = data.id;
              this.answers.forEach( (item, index) => {
                item.question = data.id;
              })
              let body = {answers: this.answers}
              this.api.post('/api/v1/question-answer/add', body ).subscribe( data => {
                alert('Kayıt Oluşturuldu');
                //this.router.navigate(['edit/' + this.slug + '/' + this.id]);
                this.router.navigate(['/list/' + this.slug]);
              })

          }, err => {
            alert(err.error.message);
            console.log(err);
          })

        } else {
          alert('En az bir doğru cevap işaretleyiniz!')
        }
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
      this.api.upload(this.fileToUpload, 'product').subscribe( data => {
        if(data.length > 0) {
          if(this.uploadType === 'single') {
            this.formGroup.patchValue({
              [this.selectedField]: data[0],
            });
          } else {
            if(this.formGroup.value[this.selectedField].length < 1) {
              this.formGroup.patchValue({
                [this.selectedField]: data,
              });
            } else {
              let datas = this.formGroup.value[this.selectedField].push(data[0])
              this.formGroup.patchValue({
                [this.selectedField]: datas,
              });
            }
          }
          this.selectedField = '';
        }
      }, err => {
        console.log(err);
      })
  }

  deleteImage(fieldName, i) {
    this.formGroup.value[fieldName].splice(i, 1);
  }

  getDataFromAPI(endpoint): Observable<any> {
    return this.api.get(endpoint);
  }
  toggleQuizCount(event): void {
    if (event.checked) { // Quiz Hakkı
      this.quizCount = true;
    } else { // Diğer
      this.quizCount = false;
    }
  }
  public hasError = (controlName: string, errorName: string) =>{
    return this.formGroup.controls[controlName].hasError(errorName);
  }
}
