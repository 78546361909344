import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  token: string;
  constructor(private http: HttpClient) { }

  isLoggedIn() { // Check if user has a token
    if(!this.token && localStorage.getItem("cmsToken")) { // Check if there is a token in localStorage
      this.token = localStorage.getItem("cmsToken")
    }
    if(this.token) {
      return true
    } else {
      return false
    }
  }

  login(body: any) { // POST login request and return observable
    return this.http.post<any>(environment.apiUrl + '/api/v1/user/panel-login', body)
  }
}
