const publicationModel = [
    {
        name: 'name',
        label: 'Başlık *',
        type: 'text'
    },
  {
    name: 'publicationType',
    label: 'Türü*',
    type: 'select',
    options: [
      {
        name: 'dergi',
        label: 'Dergi'
      },
      {
        name: 'kitap',
        label: 'Kitap'
      }
    ]
  },
    {
        name: 'description',
        label: 'Açıklama *',
        type: 'text'
    },
    {
        name: 'year',
        label: 'Yıl *',
        type: 'number'
    },
  {
    name: 'link',
    label: 'PDF Adı*',
    type: 'text'
  },
  {
    name: 'parent',
    label: 'Ana Yayın',
    type: 'ref',
    endpoint: '/api/v1/publication/all',
    options: [],
    required: false
  },
  {
    name: 'isPremium',
    label: 'Ücretli Mi?',
    type: 'toggle',
    required: false
  },
];

const publicationList = {
    title: 'Tüm Yayınlar',
    canCreate: true,
    model: publicationModel,
    displayedColumns: [
        {
            name: 'name',
            label: 'Başlık'
        }
    ]
};

export { publicationList };
