<ng-container>
    <mat-toolbar color="primary" >
        <mat-toolbar-row>
        <span>Toplu Soru Yükleme Alanı</span>
        <span class="toolbar-spacer"></span>
        <div class="toolbar-actions">
            <button [routerLink]="'/list/question'" mat-button>Tüm Sorular</button>
        </div>
        </mat-toolbar-row>
    </mat-toolbar>
    <div class="list-container" style="    padding-top: 5rem;">
        <mat-tab-group *ngIf="questionsToAdd.length < 1">
            <mat-tab label="Tek veya Çoklu Soru Yükle">
                <quill-editor [styles]="{height: '500px'}" [(ngModel)]="multiQuestion" [placeholder]="'Soru metnini bu alana giriniz'" [modules]="modules"></quill-editor>
                <button [disabled]="submitted" class="btn-submit-form" mat-raised-button color="primary" (click)="addMultiQuestions()">YÜKLE</button>
            </mat-tab>
            <mat-tab label="Ana Soru ve Alt Soruları Yükle">
                <p><b>ANA SORU:</b></p>
                <quill-editor [styles]="{height: '200px'}" [(ngModel)]="parentQuestion" [placeholder]="'Soru metnini bu alana giriniz'" [modules]="modules"></quill-editor>
                <br><br><br>
                <p><b>ALT SORULAR:</b></p>
                <quill-editor [styles]="{height: '500px'}" [(ngModel)]="childQuestion" [placeholder]="'Soru metnini bu alana giriniz'" [modules]="modules"></quill-editor>
                <button [disabled]="submitted" class="btn-submit-form" mat-raised-button color="primary" (click)="addWithParent()">YÜKLE</button>
            </mat-tab>
        </mat-tab-group>
        <div class="bulk-questions-list" *ngIf="questionsToAdd.length > 0">
            <p>*NOT: Tüm sorular için sınav türü, kategori, konu girilmesi zorunludur. Aşağıdaki seçenekler yardımıyla soruları kaydetmeden önce toplu olarak düzenleyebilirsiniz.<br></p>
            <div class="bulk-change" id="b-changer">
                <div class="change-item filter">
                  <mat-form-field appearance="fill">
                    <mat-label>Yıl</mat-label>
                    <input  type="number"  [(ngModel)]="selectedYear" matInput>
                  </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>Sınav Türü</mat-label>
                        <mat-select [(value)]="selectedExamType">
                            <mat-option [value]="">Seçiniz</mat-option>
                          <mat-option *ngFor="let examType of examTypes" [value]="examType">
                              {{examType.name}}
                          </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>Kategori</mat-label>
                        <mat-select [(value)]="selectedCategory">
                            <mat-option [value]="">Seçiniz</mat-option>
                          <mat-option *ngFor="let category of categories" [value]="category">
                              {{category.name}}
                          </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>Ana Konu</mat-label>
                        <mat-select [(value)]="selectedSubject" (selectionChange)="selectedSubSubject = null">
                            <mat-option [value]="">Seçiniz</mat-option>
                            <ng-container *ngFor="let subject of subjects">
                                <mat-option  *ngIf="!subject.parent" [value]="subject" >
                                    {{subject.name}}
                                </mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="fill" *ngIf="selectedSubject">
                        <mat-label>Alt Konu</mat-label>
                        <mat-select [(value)]="selectedSubSubject">
                            <mat-option [value]="">Seçiniz</mat-option>
                            <ng-container *ngFor="let subject of subjects">
                                <mat-option  *ngIf="subject.parent && subject.parent == selectedSubject.id" [value]="subject" >
                                    {{subject.name}}
                                </mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                    <button class="btn-submit-form filter-btn" mat-raised-button color="primary" (click)="applyChanges()">Uygula</button>
                </div>
            </div>
            <p *ngIf="this.parentQuestion"><b>ANA SORU</b></p>
            <div class="single-question" *ngIf="this.parentQuestion" [class.selected]="this.parentQuestionObject.checked" [class.invalid]="someInvalid && !this.parentQuestionObject.isValid">
                <mat-checkbox [(ngModel)]="this.parentQuestionObject.checked" (change)="checkSelections($event,0)"></mat-checkbox> <div class="question-text" [innerHTML]="this.parentQuestionObject.questionText"></div>
                <div style="clear:both"></div>
                <div class="question-meta">
                    Sınav Türü: <b>{{this.parentQuestionObject.examTypeName}}</b> -- Kategori: <b>{{this.parentQuestionObject.categoryName}}</b> -- Konu: <b>{{this.parentQuestionObject.subjectName}}</b>   -- Yıl: <b>{{this.parentQuestionObject.year}}</b>
                </div>
            </div>
            <p *ngIf="this.parentQuestion"><b>ALT SORULAR</b></p>
            <div class="bulk-select-all">
                <mat-checkbox (change)="selectAll($event)" [indeterminate]="someChecked">Tümünü Seç</mat-checkbox>
            </div>
            <div class="single-question" *ngFor="let question of questionsToAdd; let i = index" [class.selected]="question.checked" [class.invalid]="someInvalid && !question.isValid">
                <mat-checkbox [(ngModel)]="question.checked" (change)="checkSelections($event,i)"></mat-checkbox> <div class="question-text" [innerHTML]="question.questionText"></div>
                <div style="clear:both"></div>
                <div class="question-meta">
                    Sınav Türü: <b>{{question.examTypeName}}</b> -- Kategori: <b>{{question.categoryName}}</b> -- Ana Konu: <b>{{question.parentSubjectName}}</b> -- Alt Konu: <b>{{question.subjectName}}</b>  -- Yıl: <b>{{question.year}}</b>
                </div>
                <div class="question-answers">
                    <span class="question-answer" *ngFor="let answer of question.answers; let i = index" [class.correct]="answer.correct">
                        {{choices[i]}}) {{answer.answer}}
                    </span>
                </div>
            </div>
            <button [disabled]="submitted" class="btn-submit-form" mat-raised-button color="primary" (click)="saveQuestions()">Soruları Ekle</button>
        </div>
    </div>
</ng-container>
