const quizModel = [
    {
        name: 'name',
        label: 'Başlık *',
        type: 'text'
    },
    {
        name: 'intro',
        label: 'Açıklama *',
        type: 'text'
    },
    {
        name: 'examType',
        label: 'Sınav Türü *',
        type: 'ref',
        endpoint: '/api/v1/exam-type/all',
        options: []
    },
    {
      name: 'categoryId',
      label: 'Quiz Kategorisi',
      type: 'ref',
      endpoint: '/api/v1/quiz-category/all',
      options: [],
      required: false
    },
    {
        name: 'timeLimit',
        label: 'Sınav Süre Sınırı *',
        type: 'number'
    },
    {
        name: 'timeOpen',
        label: 'Açılma Zamanı',
        type: 'datetime',
        required: false
    },
    {
        name: 'timeClose',
        label: 'Kapanış Zamanı',
        type: 'datetime',
        required: false
    },
  {
    name: 'resultTime',
    label: 'Açıklanma Zamanı',
    type: 'datetime',
    required: false
  },
  {
      name: 'isOpen',
      label: 'Aktif Mi?',
      type: 'toggle',
      required: false
  },
  {
    name: 'isPremium',
    label: 'Ücretli Mi?',
    type: 'toggle',
    required: false
  },
  {
    name: 'redirectToProduct',
    label: 'Ürün olarak oluşturmak ister misin?',
    type: 'toggle',
    required: false
  }
];

const quizList = {
    title: 'Tüm Quizler',
    canCreate: true,
    model: quizModel,
    displayedColumns: [
        {
            name: 'name',
            label: 'Başlık'
        },
        {
            name: 'intro',
            label: 'Açıklama'
        }
    ]
};

export { quizList }
