import {Component, OnInit, Renderer2} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { listSettings } from '../../settings/dashboard.settings';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { SelectionModel } from '@angular/cdk/collections';
import {environment} from '../../../environments/environment';
@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html'
})
export class EditComponent implements OnInit {
  slug: string;
  settings: any;
  formData: any = [];
  formGroup: FormGroup;
  submitted = false;
  fileToUpload: File = null;
  selectedField: any;
  uploadType = 'single';
  id: string;
  data: any;
  modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote', 'code-block'],
      [{ list: 'ordered'}, { list: 'bullet' }],
      ['clean'],
    ]
  };
  answerArray = ['A', 'B', 'C', 'D' , 'E'];
  answers = [
    {id: '', correct: false, choice: 'A', answer: '', question: ''},
    {id: '', correct: false, choice: 'B', answer: '', question: ''},
    {id: '', correct: false, choice: 'C', answer: '', question: ''},
    {id: '', correct: false, choice: 'D', answer: '', question: ''},
    {id: '', correct: false, choice: 'E', answer: '', question: ''}
  ];
  answersPopulated = false;
  childQuestions = [];
  parent = {
    questionText: '',
    id: ''
  };
  quizQuestions = [];
  newQuizQuestions = [];
  filters = [];
  filterGroup: any;
  allQuestions: any;
  popupOpen = false;
  matColumns: any;
  displayedColumns: any;
  subjects: any;
  selectedGroupType: any;
  selectedQuestionGroup: any;
  selection = new SelectionModel(true, []);
  questionsToAdd: any;
  allQuizQuestions = [];
  totalDataCount = 0;
  page = 0;
  pageSize = 10;
  quizOrder = 'latest';
  quizTemplate = [];
  packageSlots = [];
  userProducts: any;
  rediretToProduct = false;
  quizCount = false;
  yearSelection = '';
  constructor(private route: ActivatedRoute, private api: ApiService, private router: Router, public dialog: MatDialog, private renderer: Renderer2) {}

  ngOnInit(): void {

    this.route.params.subscribe(params => {
      this.answersPopulated = false;
      this.childQuestions = [];
      this.settings = {};
      this.parent = {
        questionText: '',
        id: ''
      };
      this.slug = params.slug;
      this.id = params.id;
      if(listSettings[this.slug]) {
        this.settings = listSettings[this.slug];
        this.formData = this.settings?.model;
      }
      this.generateFilters();
      this.getDetailFromAPI().subscribe(data => {
        this.data = data;
        if (this.slug === 'user') {
          this.data.type = this.data.type.toString();
        }
        if (this.data.coverImage) {
          this.data.coverImage = this.data.coverImage.replace('https://elsimages.s3.eu-central-1.amazonaws.com/','');
        }
        console.log(this.data);
        if (this.formData) {
          const group = {};
          this.formData.forEach( input => {
            if (input.type === 'ref') {
              if (input.name === 'subject') {
                group['parentSubject'] = new FormControl('');
              }
              input.options = [];
              this.getDataFromAPI(input.endpoint).subscribe(data => {
                input.options = data;
              });
            }
            const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            const validations = [];
            if (input.required !== false && input.name !== 'password') {
              validations.push(Validators.required);
            }
            if (input.validateEmail) {
              validations.push(Validators.pattern(EMAIL_REGEX));
            }
            if(input.name === 'password') {
              group[input.name] = new FormControl('', validations);
            } else {
              group[input.name] = new FormControl(this.data[input.name], validations);
            }
          });
          this.formGroup = new FormGroup(group);
          if (this.slug === 'question') {
            if (this.data.qtype === 'multichoice') {
              this.populateAnswers();
            }
            if (this.data.qtype === 'parent') {
              this.populateChildQuestions();
            }
            if (this.data.parent) {
              this.populateParentQuestion(this.data.parent);
            }
          }
          if( this.slug === 'quiz') {
            this.populateSubjects();
          }
          if (this.slug === 'package') {
            this.getPackageSlots();
          }
          if(this.slug == 'user') {
            this.getUserProducts();
          }
        } else {
          let group = {};
          this.formData = [];
          this.formGroup = new FormGroup(group);
        }
      });
    });
  }
  getUserProducts() {
    this.api.get('/api/v1/user/products/' + this.id).subscribe( data => {
      this.userProducts = data;
      console.log(data);
    });
  }
  populateQuizQuestions() {
    this.api.get('/api/v1/quiz-slot/questions/' + this.id).subscribe( data => {
      this.allQuizQuestions = [];
        this.quizQuestions = data;
        this.quizQuestions.sort((a,b) =>  a.group-b.group );
        this.quizQuestions.forEach( item => { // GENERATE GROUPS FIRST
          if(this.allQuizQuestions) {
            let found = this.allQuizQuestions.find(x => x.group == item.group);
            let category = this.subjects.find(x => x.id == item.question.category);
            if(!found) {
              this.allQuizQuestions.push({
                group: item.group,
                category: category,
                questions: []
              })
            }
          }
        });
        this.quizQuestions.sort((a,b) =>  a.slot-b.slot );
        this.quizQuestions.forEach( item => { // GENERATE QUESTIONS
          let found = this.allQuizQuestions.findIndex(x => x.group == item.group);
          let sameSlot = this.allQuizQuestions[found].questions.findIndex(y => y.slot == item.slot);
          if(sameSlot < 0) {
            this.allQuizQuestions[found].questions.push(item);
          }
        })
        this.generateQuestionNumbers();
    });

  }

  autoPopulateQuestions(){
    /* todo remove
    this.api.get('/api/v1/quiz-slot/questions/' + this.id).subscribe( data => {
      if(data.length < 1 && this.allQuizQuestions.length < 1) {
          let categories = [20,21,22,23,24,25,26,27,28,29,30]
          let category;
          let group = 1;
          categories.forEach(cat => {
            category = this.subjects.find( item => item.id == cat);
            this.allQuizQuestions.push({
              group: group,
              category: category,
              questions: []
            })
            group++
          })
      } else {
      }
    })
     */
  }

  populateSubjects() {
    this.api.get('/api/v1/question-category/all').subscribe( data => {
      this.subjects = data;
      this.populateQuizQuestions();
      //this.autoPopulateQuestions();
    })
  }
  getDisplayedColumns() {
    this.matColumns = [];
    this.displayedColumns = [];
    this.displayedColumns = [
      {
        name: "questionText",
        label: "Soru",
        type: "html"
      }
    ];
    this.matColumns = this.displayedColumns.map(col => col.name);
  }
  populateAnswers() {
    this.api.get('/api/v1/question-answer/all/' + this.id).subscribe( data => {
      console.log(data);
      if(data.length > 0) {
        data.forEach( (item, index) => {
          this.answers[index] = item;
          this.answers[index].choice = this.answerArray[index];
          let validations = [];
          validations.push(Validators.required);
          this.formGroup.addControl(this.answerArray[index], new FormControl(item.answer, validations))
        });
        this.answersPopulated = true;
      } else {
        this.answers.forEach( (field) => {
          let validations = [];
          validations.push(Validators.required);
          this.formGroup.addControl(field.choice, new FormControl('', validations));
          this.answersPopulated = true;
        })
      }
    })
  }

  populateChildQuestions() {
    this.api.get('/api/v1/' + this.slug + '/parent/' + this.id).subscribe( data => {
      this.childQuestions = data;
    })
  }

  populateParentQuestion(id) {
    this.api.get('/api/v1/' + this.slug + '/' + id).subscribe( data => {
      this.parent = data;
    })
  }
  getDetailFromAPI(): Observable<any> {
    return this.api.get('/api/v1/' + this.slug + '/' + this.id);
  }

  onSubmit() {
    let body = {};
    if (this.slug === 'product') {
      if (this.formGroup.value.publicationId && this.formGroup.value.serviceId) {
        return alert('Hem Yayın hem Servis aynı anda seçili olamaz!');
      }
    }
    if (this.slug === 'user') {
      if (this.formGroup.value.type) {
        this.formGroup.value.type = parseInt(this.formGroup.value.type);
      }
    }
    if(this.formGroup.valid) {
      let url = '/api/v1/' + this.slug + '/' + this.id;
      if(this.slug == 'user') {
        if(!this.formGroup.value.password) {
          delete this.formGroup.value.password;
        }
      }
      let object = this.formGroup.value;
      let data = this.data;
      if(this.formGroup.value.reducedPrice === null) {
        this.formGroup.value.reducedPrice = 0;
      }
      if(this.formGroup.value.parentSubject && !this.formGroup.value.subject) this.formGroup.value.subject = this.formGroup.value.parentSubject;
      delete this.formGroup.value['parentSubject'];
      Object.keys(object).map(function(objectKey, index) { // Get only values that changed
          if( object[objectKey] !== data[objectKey] ) {
            body[objectKey] = object[objectKey];
          }
      });
      console.log(body);
      if(Object.keys(body).length !== 0) {
          this.api.patch(url, body).subscribe(data => {
            if (this.slug === 'question') {
              this.validateAndSaveQuestion();
            }
            alert("Kayıt Güncellendi!");
            console.log(this.formGroup.value.redirectToProduct);
            if(this.formGroup.value.redirectToProduct) {
              this.router.navigate(['create/product/'], {queryParams: {quizId: data.id, productType: 'quiz', name: data.name}});
            } else {
              if(data) {
                this.router.navigate(['/list/' + this.slug]);
              }
            }
          }, err => {
            alert(err.error.message);
            console.log(err);
          })
      }
    }
  }
  validateAndSaveQuestion() {
    let url = `/api/v1/${this.slug}/${this.id}`;
    let validAnswers = false;
    this.answers.forEach( (item, index) => {
      item.answer = this.formGroup.value[this.answerArray[index]];
      if(item.correct) {
        validAnswers = true;
      }
    });
    if (validAnswers) {
      this.api.patch(url, this.formGroup.value).subscribe(data => {
        let body = {answers: this.answers};
        this.api.patch('/api/v1/question-answer/' + this.id, body ).subscribe( data => {
          this.router.navigate(['/list/' + this.slug]);
          //alert('Değişiklikler uygulandı');
        });

        /*this.answers.forEach( (item, index) => {
          if(item.id) {
            body = {
              answer: item.answer
            }
            this.api.patch('/api/v1/question-answer/' + item.id, body ).subscribe( data => {
              console.log('şık kaydedildi');
            })
          }
        })*/


      }, err => {
        alert(err.error.message);
        console.log(err);
      })

    } else {
      alert('En az bir doğru cevap işaretleyiniz!')
    }
  }
  dateChange(event, field: any) {
    this.formGroup.patchValue({[field.name]: event.value.utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")})
  }
  radioChange(event) {
    this.answers.forEach( (item, index) => {
      if(item.choice === event.value) {
        item.correct = true;
      } else {
        item.correct = false;
      }
    })
  }
  deleteImage(fieldName, i) {
    this.formGroup.value[fieldName].splice(i, 1);
  }
  toggleQuizCount(event): void {
    if (event.checked) { // Quiz Hakkı
      this.quizCount = true;
    } else { // Diğer
      this.quizCount = false;
    }
  }
  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
      this.api.upload(this.fileToUpload, 'product').subscribe( data => {
        if(data.length > 0) {
          if(this.uploadType === 'single') {
            this.formGroup.patchValue({
              [this.selectedField]: data[0],
            });
          } else {
            if(this.formGroup.value[this.selectedField].length < 1) {
              this.formGroup.patchValue({
                [this.selectedField]: data,
              });
            } else {
              let datas = this.formGroup.value[this.selectedField].push(data[0])
              this.formGroup.patchValue({
                [this.selectedField]: datas,
              });
            }
          }
          this.selectedField = '';
        }
      }, err => {
        console.log(err);
      })
  }
  getDataFromAPI(endpoint): Observable<any> {
    return this.api.get(endpoint);
  }
  public hasError = (controlName: string, errorName: string) =>{
    return this.formGroup.controls[controlName].hasError(errorName);
  }
  deleteItem() {
    var r = confirm("Silmek istediğinize emin misiniz ?");
    if (r == true) {
      let url = '/api/v1/' + this.slug + '/' + this.id;
      this.api.del(url).subscribe(data => {
        if(data) {
          alert("Kayıt Silindi")
          this.router.navigate(['list/' + this.slug])
        }
      })
    }
  }

  openDialog(): void {
    this.popupOpen = true;
  }
  closeDialog(): void {
    this.popupOpen = false;
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.allQuestions.filter = filterValue.trim().toLowerCase();
  }

  addQuestion(row, slot): void {
    if (this.selectedQuestionGroup.category.id === row.category) {
      const index = this.allQuizQuestions.findIndex(group => group.group === this.selectedQuestionGroup.group);
      if (this.allQuizQuestions[index]) {
        if (this.allQuizQuestions[index].questions) {
          if (this.allQuizQuestions[index].questions.findIndex(q => q.question.id === row.id) > -1) {
            return alert('Bu soru zaten ekli!');
          }
        }
      }
      if (row.qtype === 'parent') {
        this.saveParentQuestionToQuiz(row, index, slot);
      } else {
        this.saveQuestionToQuiz(row, index, slot);
      }
    } else {
      alert(`Eklediğiniz soru "${this.selectedQuestionGroup.category.name}" kategorisine ait olmalıdır!`)
    }

  }

  saveOrder() {
    let body = {
      quizSlots: []
    };
    this.allQuizQuestions.forEach( (group, groupIndex) => {
      if(group.questions.find(g => g.slot === 0)) {
        group.questions.forEach( (slot, slotIndex) => {
          body.quizSlots.push({id: slot.id, group: groupIndex + 1, slot: slotIndex })
        })
      } else {
        group.questions.forEach( (slot, slotIndex) => {
          body.quizSlots.push({id: slot.id, group: groupIndex + 1, slot: slotIndex + 1 })
        })
      }
    });
    this.api.patch('/api/v1/quiz-slot/bulk-patch', body).subscribe( data => {
      alert('Yeni sıralama kaydedildi!');
      this.populateQuizQuestions();
      this.generateQuestionNumbers();
    })
  }

  saveQuestionToQuiz(question, index, slot): void {
    const body = {
      quizId: parseInt(this.id),
      questionId: question.id,
      group: index + 1,
      slot
    };
    this.api.post('/api/v1/quiz-slot/add', body).subscribe( data => {
      this.populateQuizQuestions();
      this.generateQuestionNumbers();
      this.unSelectAll();
      this.filter();
    });
  }

  saveParentQuestionToQuiz(question, index, slot): void {
    const body = {
      quizId: parseInt(this.id),
      questionId: question.id,
      group: index + 1,
      slot: 0
    };

    this.api.post('/api/v1/quiz-slot/add', body).subscribe( data => {
       this.populateQuizQuestions();
       this.generateQuestionNumbers();
    });

    // Get and save all child questions slot
    this.api.get('/api/v1/question/parent/' + question.id).subscribe(data => {
      let childQuestions = data;
      let slot = 1;
      childQuestions.forEach( childQuestion => {
        this.saveQuestionToQuiz(childQuestion, index, slot);
        slot++
      });
    });
  }

  removeQuestion(id) {
    let url = '/api/v1/quiz-slot/' + id;
    var r = confirm("Soruyu quizden kaldırmak istediğinize emin misiniz?");
    if (r == true) {
      this.api.del(url).subscribe(data => {
        alert("Soru Kaldırıldı!");
        this.populateQuizQuestions();
        this.generateQuestionNumbers();
        this.unSelectAll();
        this.filter();
      })
    }
  }

  addGroup() {
    this.selectedQuestionGroup = null;
    this.questionsToAdd = null;
    if(!this.selectedGroupType) {
      alert('Lütfen bir grup türü seçin!')
    } else {
      this.allQuizQuestions.push({
        group: this.allQuizQuestions.length + 1,
        category: this.selectedGroupType,
        questions: []
      })
    }
  }

  deleteGroup(group) {
    if(group.questions.length > 0) {
      alert('Lütfen önce gruptaki soruları silin')
    } else {
      let index = this.allQuizQuestions.findIndex(item => item.group === group.group);
      this.allQuizQuestions.splice(index, 1);
    }
  }

  addQuestionToGroup(g) {
    this.selectedQuestionGroup = g;
    this.getQuestionsForGroup();
    // let index = this.allQuizQuestions.findIndex(group => group.group === g);
    // this.allQuizQuestions[index].questions.push({
    //   text: "Örnek soru " + Math.random()
    // })
    // this.generateQuestionNumbers();
  }

  getQuestionsForGroup() {
    this.filterGroup.patchValue({category: this.selectedQuestionGroup.category.id});
    this.filter();
  }

  pageChange(event){
    this.selection.clear();
    this.page = event.pageIndex;
    this.pageSize = event.pageSize;
    this.filter();
  }

  generateFilters() {
    this.filters = [];
    let filterSettings = ['category','subject','questionText','year'];
    if(filterSettings) {
      let group = {}
      filterSettings.forEach( item => {
        group[item] = new FormControl('');
        const obj = listSettings['question'].model.find( model => model.name === item);
        if(obj.type === 'ref') {

          obj.options = [];
          this.getDataFromAPI(obj.endpoint).subscribe(data => {
            obj.options = data;
          });
        }
        this.filters.push(listSettings['question'].model.find( model => model.name === item))
      })
      this.filterGroup = new FormGroup(group);
      console.log(this.filters);
      console.log(this.filterGroup);
    }
  }
  yearChange(event) {
    if (event.value) {
      this.yearSelection = event.value;
    }
  }

  filter() {
    var queryString = Object.keys(this.filterGroup.value)
    .filter(key => (this.filterGroup.value[key] !== '' && this.filterGroup.value[key] !== undefined))
    .map(key => key + '=' + this.filterGroup.value[key])
    .join('&');
    let filterUrl = '/api/v1/question/search?' + queryString;
    if(!queryString) {
      filterUrl = filterUrl + 'page=' + this.page + '&pageSize=' + this.pageSize;
    } else {
      filterUrl = filterUrl + '&page=' + this.page + '&pageSize=' + this.pageSize;
    }
    if(this.quizOrder === 'random') {
      filterUrl = filterUrl + '&isRandom=1'
    }
    if(this.yearSelection) {
      filterUrl = filterUrl + '&yearSort=' + this.yearSelection;
    }
    if(filterUrl) {
      this.api.get(filterUrl).subscribe(data => {
        let added = [];
        this.allQuizQuestions.forEach(function(item){
          item.questions.forEach(function(question){
            added.push(question);
          })
        })
        let datas = data.rows.filter(function(obj) {
            return !added.some(function(obj2) {
                return obj.id == obj2.question.id;
            });
        });
        this.questionsToAdd = new MatTableDataSource(datas);
        this.totalDataCount = data.count;
      }, error => {
        if(error.status == 401) {
          localStorage.removeItem("cmsToken");
          this.router.navigate(['login']);
        }
      })
    }
  }

  generateQuestionNumbers() {
    let count = 1;
    this.allQuizQuestions.forEach( group => {
      if(group.questions.length > 0) {
        if(group.questions.length === 1) {
          group.number = count;
        } else {
          if(group.questions.find(x => x.slot === 0)) {
            group.number = count + ' - ' + (count + group.questions.length - 1 - 1);
          } else {
            group.number = count + ' - ' + (count + group.questions.length - 1);
          }

        }
        group.questions.forEach( question => {
          if(question.slot !== 0) {
            question.number = count + ' - ';
            count++
          } else {
            let regex = /{(.*?)}/gs;
            let resultMatchGroup = question.question.questionText.match(regex)
            if(resultMatchGroup) {
              let desiredRes = resultMatchGroup.map(match => match.replace(regex, "$1"))
              desiredRes.forEach(item => {
                let textToFind = '{'+item+'}';
                question.question.questionText = question.question.questionText.replace(textToFind, `(${count + parseInt(item) - 1})`)
              });
            }
            question.number = '';
          }
        })
      }
    })
  }

  drop(event) {

    moveItemInArray(this.allQuizQuestions, event.previousIndex, event.currentIndex);
    this.generateQuestionNumbers();
  }

  dropChild(event, group) {
    moveItemInArray(group.questions, event.previousIndex, event.currentIndex);
    this.generateQuestionNumbers();
  }

  clickRow(row) {
    //console.log(row);
    // console.log(this.selection)
  }

  selecitonChanged() {
    // console.log(this.selection);
  }

  isAllSelected() {
    const numSelected = this.selection?.selected.length;
    const numRows = this.questionsToAdd.data.length;
    return numSelected === numRows;
  }

  unSelectAll() {
    this.selection.clear()
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.questionsToAdd.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection?.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  addQuestions(): void {
    const index = this.allQuizQuestions.findIndex(group => group.group === this.selectedQuestionGroup.group);
    let slot = 1;
    if (index > -1) {
      if (this.allQuizQuestions[index].questions) {
        slot = this.allQuizQuestions[index].questions.length + 1;
      }
    }
    this.selection.selected.forEach( row => {
      this.addQuestion(row, slot);
      slot++;
    });
  }

  recalculateQuiz(): void {
    this.api.get('/api/v1/quiz/result/' + this.id).subscribe( data => {
      alert('Sınav sonuçları güncellendi!');
    });
  }

  getPackageSlots(): void {
    this.api.get('/api/v1/package-slot/products/' + this.id).subscribe( data => {
      this.packageSlots = data;
    });
  }

  downloadQuiz(): void {
    this.api.getFile('/api/v1/quiz/docx/' + this.id).subscribe( data => {
      console.log(data);
      const blob = new Blob([data.body], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
      const url = window.URL.createObjectURL(blob);
      window.open(url);
    });
  }
}
