<div class="sidenav-container">
    <div class="sidenav-header">
        ELS CMS
    </div>
    <div class="sidenav-menu">
        <mat-nav-list>
            <ng-container *ngFor="let item of menu">
                <mat-divider></mat-divider>
                    <mat-list-item *ngIf="!item.subMenu" [routerLink]="item.path" [routerLinkActive]="'active'" [class.active]="item.label === active">
                        <mat-icon>{{item.icon}}</mat-icon>{{item.label}}
                    </mat-list-item>
                    <mat-list-item *ngIf="item.subMenu" [class.active]="item.expanded" (click)="item.expanded = !item.expanded">
                        <mat-icon>{{item.icon}}</mat-icon>{{item.label}}
                    </mat-list-item>
                    <ng-container *ngIf="item.subMenu && item.expanded" >
                        <mat-list-item *ngFor="let child of item.subMenu" [routerLink]="child.path" [routerLinkActive]="'active'" class="submenu" [class.active]="child.label === active">
                            <mat-icon>{{child.icon}}</mat-icon>{{child.label}}
                        </mat-list-item>
                    </ng-container>
                </ng-container>
                <mat-divider></mat-divider>
                <mat-list-item (click)="logout()">
                    <mat-icon>exit_to_app</mat-icon>Çıkış Yap
                </mat-list-item>
                <mat-divider></mat-divider>
          </mat-nav-list>
    </div>
</div>
