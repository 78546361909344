import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { AuthGuard } from './auth/auth.guard';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ListComponent } from './pages/list/list.component';
import { CreateComponent } from './pages/create/create.component';
import { EditComponent } from './pages/edit/edit.component';
import { BulkQuestionsComponent } from './pages/bulk-questions/bulk-questions.component';
import { CacheComponent } from './pages/cache/cache.component';
import { EmailerComponent } from './pages/emailer/emailer.component';
import {PackageSlotsComponent} from './pages/package-slots/package-slots.component';
import {PackageUsersComponent} from './pages/package-users/package-users.component';


const routes: Routes = [
  { path:  '', component: DashboardComponent, canActivate: [AuthGuard]},
  { path:  'login', component:  LoginComponent},
  { path:  'list/:slug', component: ListComponent, canActivate: [AuthGuard]},
  { path:  'create/:slug', component: CreateComponent, canActivate: [AuthGuard]},
  { path:  'create/:slug/:id', component: CreateComponent, canActivate: [AuthGuard]},
  { path:  'edit/:slug/:id', component: EditComponent, canActivate: [AuthGuard]},
  { path:  'bulk-questions', component: BulkQuestionsComponent, canActivate: [AuthGuard]},
  { path:  'cache', component: CacheComponent, canActivate: [AuthGuard]},
  { path:  'emailer', component: EmailerComponent, canActivate: [AuthGuard]},
  { path:  'package-slots', component: PackageSlotsComponent, canActivate: [AuthGuard]},
  { path:  'package-users', component: PackageUsersComponent, canActivate: [AuthGuard]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
