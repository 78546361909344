const purchaseModel = [
  {
    name: 'createdAt',
    label: 'Tarih',
    type: 'text'
  },
  {
    name: 'price',
    label: 'Fiyat',
    type: 'text'
  },
  {
    name: 'orderStatus',
    label: 'Ödeme Durumu',
    type: 'text'
  },
  {
    name: 'userId',
    label: 'Kullanıcı',
    type: 'ref',
    endpoint: '/api/v1/user/all',
    options: []
  },
  {
    name: 'packageId',
    label: 'Paket',
    type: 'ref',
    endpoint: '/api/v1/package/all',
    options: []
  },
];

const purchaseList = {
  title: 'Tüm Siparişler',
  canCreate: false,
  model: purchaseModel,
  displayedColumns: [
    {
      name: 'createdAt',
      label: 'Tarih'
    },
    {
      name: 'price',
      label: 'Fiyat'
    },
    {
      name: 'orderStatus',
      label: 'Ödeme Durumu'
    }
  ]
};

export { purchaseList };
