const subjectModel = [
    {
        name: "name",
        label: "Başlık *",
        type: "text"
    },
    {
        name: "info",
        label: "Açıklama *",
        type: "text"
    },
    {
        name: "year",
        label: "Yıl *",
        type: "number",
        min: 1900,
        max: 2100
    },
    {
        name: "courseLink",
        label: "Kurs Linki",
        type: "text",
        required: false
    }
]

const subjectList = {
    title: "Tüm Başlıklar",
    canCreate: true,
    model: subjectModel,
    displayedColumns: [
        {
            name: "name",
            label: "Başlık"
        }
    ]
}

export { subjectList }