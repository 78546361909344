const packageModel = [
  {
    name: 'name',
    label: 'Başlık *',
    type: 'text'
  },
  {
    name: 'coverImage',
    label: 'Ana Görsel',
    type: 'text',
    required: false
  },
  {
    name: 'price',
    label: 'Fiyat *',
    type: 'number'
  },
  {
    name: 'reducedPrice',
    label: 'İndirimli Fiyat',
    type: 'number',
    required: false
  },
  {
    name: 'star',
    label: 'Yıldız',
    type: 'number',
    required: false
  },
  {
    name: 'explanation',
    label: 'Açıklama',
    type: 'text',
    required: false
  },
  {
    name: 'longDescription',
    label: 'Uzun Açıklama',
    type: 'html',
    required: false
  },
  {
    name: 'order',
    label: 'Sıra',
    type: 'number',
    required: false
  },
  {
    name: 'isVisible',
    label: 'Görünür Mü?',
    type: 'toggle',
    required: false
  }
];

const packageList = {
  title: 'Tüm Paketler',
  canCreate: true,
  model: packageModel,
  displayedColumns: [
    {
      name: 'name',
      label: 'Başlık'
    }
  ]
};

export { packageList };
