import { Injectable, ComponentFactoryResolver } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient, private auth: AuthService) { }

  get(endpoint): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .append('x-auth', this.auth.token);
    return this.http.get( environment.apiUrl + endpoint, {headers})
  }

  post(endpoint, body): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .append('x-auth', this.auth.token);
    return this.http.post( environment.apiUrl + endpoint, body, {headers})
  }

  patch(endpoint, body): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .append('x-auth', this.auth.token);
    return this.http.patch( environment.apiUrl + endpoint, body, {headers})
  }

  del(endpoint): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .append('x-auth', this.auth.token);
    return this.http.delete( environment.apiUrl + endpoint, {headers})
  }

  upload(file, type): Observable<any> {
    const headers = new HttpHeaders()
      .set('x-auth', this.auth.token);
      let fd = new FormData();
      fd.append('image', file);
      return this.http.post( environment.apiUrl + '/api/v1/upload?type=' + type, fd, {headers})
  }

  getFile(endpoint): Observable<any> {
    const headers = new HttpHeaders()
      .set('x-auth', this.auth.token);
    return this.http.get( environment.apiUrl + endpoint, {
      headers,
      observe: 'response',
      responseType: 'arraybuffer'
    })
  }

}
