<div class="main-container">
    <mat-card class="login-card">
        <mat-card-header>
            <mat-card-title>Hoşgeldiniz</mat-card-title>
        </mat-card-header>
        <mat-divider></mat-divider>
        <mat-card-content>
            <br><br>
            İçerik yönetimi sistemi üzerinden içerikleri düzenlemek veya yeni içerik eklemek için sol menüyü kullanabilirsiniz.
            <br><br>
        </mat-card-content>
    </mat-card>  
</div>
