<ng-container *ngIf="settings">
    <mat-toolbar color="primary" >
        <mat-toolbar-row>
        <span>Düzenle</span>
        <span class="toolbar-spacer"></span>
        <div class="toolbar-actions">
            <button [routerLink]="'/list/' + slug" mat-button>{{settings.title}}</button>
        </div>
        </mat-toolbar-row>
    </mat-toolbar>
    <div class="create-container" *ngIf="formData.length > 0">
        <div class="mat-elevation-z8">
            <form *ngIf="formGroup" [formGroup]="formGroup" (ngSubmit)="onSubmit()" class="create-form">
                <ng-container *ngFor="let field of formData">
                    <ng-container *ngIf="!field.noedit">
                    <mat-form-field appearance="outline" *ngIf="field.type === 'text'">
                        <mat-label>{{field.label}}</mat-label>
                        <input formControlName={{field.name}} matInput>
                        <mat-error *ngIf="hasError(field.name, 'required')">Bu alanın dolurulması zorunludur</mat-error>
                        <mat-error *ngIf="hasError(field.name, 'pattern')">Lütfen geçerli bir mail adresi giriniz</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" *ngIf="field.type === 'number'">
                        <mat-label>{{field.label}}</mat-label>
                        <input type="number" formControlName={{field.name}} matInput>
                        <mat-error *ngIf="hasError(field.name, 'required')">Bu alanın dolurulması zorunludur</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" *ngIf="field.type === 'select'">
                        <mat-label>{{field.label}}</mat-label>
                        <mat-select formControlName={{field.name}}>
                          <mat-option *ngFor="let option of field.options" [value]="option.name">
                            {{option.label}}
                          </mat-option>
                        </mat-select>
                        <mat-error *ngIf="hasError(field.name, 'required')">Bu alanın dolurulması zorunludur</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" *ngIf="field.type === 'textarea'">
                        <mat-label>{{field.label}}</mat-label>
                        <textarea formControlName={{field.name}} matInput></textarea>
                        <mat-error *ngIf="hasError(field.name, 'required')">Bu alanın dolurulması zorunludur</mat-error>
                    </mat-form-field>
                    <div class="custom-form-field" *ngIf="field.type === 'toggle'">
                        <mat-slide-toggle [color]="'primary'" formControlName={{field.name}} matInput>{{field.label}}</mat-slide-toggle>
                    </div>
                    <mat-form-field appearance="outline" *ngIf="field.type === 'image'">
                        <img class="uploadedimage" src={{formGroup.value[field.name]}} *ngIf="formGroup.value[field.name]">
                        <input formControlName={{field.name}} matInput [hidden]="true">
                        <button type="button" mat-raised-button (click)="fileUpload.click();this.selectedField = field.name;this.uploadType = 'single'">{{field.label}}</button>
                    </mat-form-field>
                    <mat-form-field appearance="outline" *ngIf="field.type === 'multiimage'">
                        <ng-container *ngFor="let image of formGroup.value[field.name]; let i = index">
                            <img class="uploadedimage" src={{image}} (click)="deleteImage(field.name, i)">
                        </ng-container>
                        <input formControlName={{field.name}} matInput [hidden]="true">
                        <button type="button" mat-raised-button (click)="fileUpload.click();this.selectedField = field.name;this.uploadType = 'multi'">{{field.label}}</button>
                    </mat-form-field>
                      <mat-form-field appearance="outline"
                                      *ngIf="
                                    field.type === 'ref' &&
                                    field.name !== 'subject' &&
                                    (field.name !== 'quizId' || !quizCount) &&
                                    (field.name !== 'publicationId' || !quizCount) &&
                                    (field.name !== 'quizCategoryId' || quizCount)
">
                        <mat-label>{{field.label}}</mat-label>
                        <mat-select formControlName={{field.name}}>
                          <mat-option [value]="null">
                            Seçilmedi
                          </mat-option>
                          <mat-option *ngFor="let option of field.options" [value]="option.id">
                            {{option.name}}
                          </mat-option>
                        </mat-select>
                        <mat-error *ngIf="hasError(field.name, 'required')">Bu alanın dolurulması zorunludur</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" *ngIf="field.type === 'datetime'">
                        <mat-label>{{field.label}}</mat-label>
                        <input matInput  (dateChange)="dateChange($event, field)" [ngxMatDatetimePicker]="picker" placeholder="Choose a date" formControlName={{field.name}}>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <ngx-mat-datetime-picker #picker [showSpinners]="true" [showSeconds]="false"
                           [stepHour]="true" [stepMinute]="true" [stepSecond]="false"
                           [touchUi]="true" [color]="'primary'" [enableMeridian]="false"
                           [disableMinute]="false" [hideTime]="false">
                        </ngx-mat-datetime-picker>
                     </mat-form-field>
                    <quill-editor [styles]="{height: '250px'}" *ngIf="field.type === 'html'" [modules]="modules" [placeholder]="'Soru metnini bu alana giriniz'" formControlName={{field.name}}></quill-editor>
                    <ng-container *ngIf="field.type === 'answers' && this.data.qtype === 'multichoice' && answersPopulated">

                        <div class="answers-container">
                            <p><b>Cevaplar:</b></p>
                            <mat-radio-group>
                                <div class="answer" *ngFor="let item of answers; let i = index">
                                    <mat-radio-button value={{item.choice}} [checked]="item.correct" (change)="radioChange($event)"></mat-radio-button> {{item.choice}}
                                    <quill-editor [styles]="{height: '60px'}" [placeholder]="'Cevap metnini bu alana giriniz'" [modules]="modules" formControlName={{item?.choice}}></quill-editor>
                                </div>
                            </mat-radio-group>
                        </div>
                    </ng-container>

                </ng-container>
                </ng-container>

                <input #fileUpload id="fileUpload" type="file" style="display:none" (change)="handleFileInput($event.target.files)" />

                <button [disabled]="submitted" class="btn-submit-form" mat-raised-button color="primary">Güncelle</button>
            </form>
            <div class="user-products" *ngIf="slug === 'user' && userProducts">
              <div style="margin: 20px">
                <h2>Kullanıcının Sahip Olduğu Ürünler:</h2>
                <p *ngFor="let item of userProducts.available"><b>{{item?.product?.name}}</b></p>
              </div>
              <div style="margin: 20px">
                <h2>Kullanıcının Kullandığı Ürünler:</h2>
                <p *ngFor="let item of userProducts.used"><b style="color:#cccccc">{{item?.product?.name}}</b></p>
              </div>
            </div>
            <div style="padding: 0 40px;" *ngIf="packageSlots.length > 0">
              <p><b>Paket İçeriği:</b></p>
              <p *ngFor="let slot of packageSlots"><b>Ürün:</b> {{slot.product.name}} <span *ngIf="slot.count" style="color:#3f50b5;"><b>({{slot.count}})</b></span></p>
            </div>

            <button [disabled]="submitted" (click)="recalculateQuiz()" *ngIf="this.slug === 'quiz'" style="margin-left:30px;" class="btn-submit-form" mat-raised-button color="primary">Sonuçları Tekrar Hesapla</button><br>
            <div><button class="delete-button" mat-raised-button color="warn" (click)="deleteItem()">Sil</button><div style="clear:both"></div></div>

            <div class="child-questions" *ngIf="slug === 'quiz'" style="display:none;">
                <button class="btn-submit-form" mat-raised-button color="primary" (click)="openDialog()">Yeni Soru Ekle</button>
            </div>
            <div class="child-questions" *ngIf="quizQuestions.length > 0" style="display:none">
                <p><strong>Quiz'e bağlı sorular:</strong></p>
                <p>Not: Kaldırmak istediğiniz sorunun üzerine tıklayın</p>
                <ng-container *ngFor="let item of quizQuestions">
                    <mat-card class="child-question" [innerHTML]="item.question.questionText" (click)="removeQuestion(item.id)"></mat-card>
                </ng-container>
            </div>
            <div class="child-questions" *ngIf="childQuestions.length > 0">
                <p><strong>Bu soruya ait alt sorular</strong></p>
                <ng-container *ngFor="let item of childQuestions">
                    <mat-card class="child-question" [innerHTML]="item.questionText" [routerLink]="'/edit/question/' + item.id"></mat-card>
                </ng-container>
            </div>
            <div class="add-child" *ngIf="data?.qtype === 'parent'">
                <button class="" mat-raised-button color="primary" [routerLink]="'/create/question/' + id">Alt Soru Ekle</button>
            </div>
            <div class="parent-question" *ngIf="data?.parent">
                <p><strong>Bu sorunun bağlı olduğu ana soru</strong></p>
                <mat-card class="child-question" [innerHTML]="parent?.questionText" [routerLink]="'/edit/question/' + parent.id + '' "></mat-card>
            </div>
        </div>
    </div>
</ng-container>
<div class="quiz-creeator-container" *ngIf="slug === 'quiz'">
    <div class="quiz-creator-item mat-elevation-z8">
        <div class="quiz-creator child-questions" >
            <mat-form-field appearance="fill">
                <mat-label>Grup Türü</mat-label>
                <mat-select [(ngModel)]="selectedGroupType">
                    <mat-option [value]="">Seçiniz</mat-option>
                    <mat-option *ngFor="let value of subjects" [value]="value">
                        {{value.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <button class="btn-submit-form" mat-raised-button color="primary" (click)="addGroup()">Yeni Grup Ekle</button>
            <!-- <button class="btn-submit-form" mat-raised-button color="primary" (click)="openDialog()">Paragraf Sorusu Ekle</button>
            <button class="btn-submit-form" mat-raised-button color="primary" (click)="openDialog()">Tekli Soru Ekle</button> -->
            <div class="all-quiz-questions">

                <mat-accordion>
                    <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
                    <mat-expansion-panel *ngFor="let group of allQuizQuestions; let i = index" [class.selected]="selectedQuestionGroup == group" cdkDrag>
                        <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{group?.number}} {{group?.category?.groupText}}
                        </mat-panel-title>
                        </mat-expansion-panel-header>
                            <button class="btn-submit-form" mat-raised-button color="primary" (click)="addQuestionToGroup(group)">Bu Gruba Soru Ekle</button> <button class="btn-submit-form" mat-raised-button color="warn" (click)="deleteGroup(group)">Bu Grubu Sil</button>
                            <div cdkDropList class="example-list" (cdkDropListDropped)="dropChild($event, group)">
                                <mat-card *ngFor="let item of group.questions" class="child-question" [innerHTML]="item.number + item.question.questionText" (click)="removeQuestion(item.id)" cdkDrag></mat-card>
                            </div>
                    </mat-expansion-panel>
                    </div>
                </mat-accordion>
            </div>
            <button class="btn-submit-form" mat-raised-button color="primary" (click)="saveOrder()">Sıralamayı Güncelle</button>
        </div>
    </div>
    <div class="quiz-creator-item mat-elevation-z8">
        <div class="quiz-creator child-questions">
            <div class="filter quiz-filter">
                <mat-form-field appearance="fill">
                    <mat-label>Sıralama</mat-label>
                    <mat-select [(ngModel)]="quizOrder">
                        <mat-option [value]="'latest'">En Son Eklenen</mat-option>
                        <mat-option [value]="'random'">Rastgele</mat-option>
                    </mat-select>
                </mat-form-field>
                <form *ngIf="filterGroup" [formGroup]="filterGroup" (ngSubmit)="filter()" class="filter-form">
                    <ng-container *ngFor="let filter of filters">
                        <mat-form-field appearance="fill" *ngIf="filter.type === 'ref'">
                            <mat-label>{{filter.label}}</mat-label>
                            <mat-select formControlName={{filter.name}}>
                                <mat-option [value]="">Hepsi</mat-option>
                                <mat-option *ngFor="let value of filter.options" [value]="value.id">
                                {{value.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="fill" *ngIf="filter.type === 'select'">
                            <mat-label>{{filter.label}}</mat-label>
                            <mat-select formControlName={{filter.name}}>
                                <mat-option [value]="">Hepsi</mat-option>
                                <mat-option *ngFor="let value of filter.options" [value]="value.name">
                                {{value.label}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="fill" *ngIf="filter.type === 'html' || filter.type === 'text'">
                            <mat-label>{{filter.label}}</mat-label>
                            <input formControlName={{filter.name}} matInput>
                        </mat-form-field>
                        <mat-form-field appearance="fill" *ngIf="filter.type === 'number'">
                          <mat-label>{{filter.label}}</mat-label>
                          <input type="number" formControlName={{filter.name}} matInput>
                        </mat-form-field>
                      <ng-container *ngIf="filter.name === 'year'">
                        <div class="question-year">
                          <mat-form-field appearance="fill">
                            <mat-label>Yıl Büyüktür Küçüktür</mat-label>
                            <mat-select (selectionChange)="yearChange($event)">
                              <mat-option [value]="">Seçilmemiş</mat-option>
                              <mat-option [value]="'greaterThan'">Büyüktür</mat-option>
                              <mat-option [value]="'lessThan'">Küçüktür</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </ng-container>
                    </ng-container>
                    <button class="btn-submit-form filter-btn" mat-raised-button color="primary">Filtrele</button>
                </form>
            </div>
            <table mat-table [dataSource]="questionsToAdd" class="list-table" *ngIf="questionsToAdd">
                <!-- Created Column -->
                <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef>
                    <mat-checkbox (change)="$event ? masterToggle() : null; selecitonChanged()"
                                    [checked]="selection.hasValue() && isAllSelected()"
                                    [indeterminate]="selection.hasValue() && !isAllSelected()"
                                    [aria-label]="checkboxLabel()">
                    </mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let row">
                    <mat-checkbox (click)="$event.stopPropagation(); selecitonChanged()"
                                    (change)="$event ? selection.toggle(row) : null"
                                    [checked]="selection.isSelected(row)"
                                    [aria-label]="checkboxLabel(row)">
                    </mat-checkbox>
                    </td>
                </ng-container>
                <ng-container matColumnDef="questionText">
                    <th mat-header-cell *matHeaderCellDef> Soru </th>
                    <td mat-cell *matCellDef="let element">
                        <span [innerHTML]="element['questionText']"></span>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="['select','questionText']"></tr>
                <tr mat-row *matRowDef="let row; columns: ['select','questionText'];" (click)="selection.toggle(row)"></tr>
            </table>
            <button class="btn-submit-form" mat-raised-button color="primary" (click)="addQuestions()">EKLE</button>
            <mat-paginator (page)="pageChange($event)" [length]="totalDataCount" [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>

        </div>
    </div>
    <div class="get-doc" *ngIf="slug === 'quiz'">
      <button mat-raised-button (click)="downloadQuiz()">Quiz Doc İndir</button>
    </div>
    <div style="clear:both"></div>
</div>

<div class="popup-overlay" *ngIf="popupOpen">
    <div class="popup-content">
        <p>Eklemek istediğiniz aşağıdan seçiniz!</p>
        <mat-form-field *ngIf="!settings?.filter">
            <mat-label>Arama</mat-label>
            <input matInput (keyup)="applyFilter($event)">
        </mat-form-field>
        <div class="popup-questions">
                <table mat-table [dataSource]="allQuestions" class="list-table" *ngIf="allQuestions && allQuestions.filter">
                    <!-- Created Column -->
                    <ng-container matColumnDef="{{column.name}}" *ngFor="let column of displayedColumns">
                        <th mat-header-cell *matHeaderCellDef> {{column.label}} </th>
                        <td mat-cell *matCellDef="let element">
                            <span *ngIf="!column.nested && column.type !== 'html'">{{element[column.name]}}</span>
                            <span *ngIf="!column.nested && column.type === 'html'" [innerHTML]="element[column.name]"></span>
                            <span *ngIf="column.nested && element[column.name]">{{element[column.name][column.nested]}}</span>
                            <span *ngIf="column.nested && !element[column.name]"></span>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="matColumns"></tr>
                    <tr (click)="addQuestion(row.id)" mat-row *matRowDef="let row; columns: matColumns;"></tr>
                </table>
                <mat-paginator *ngIf="allQuestions && allQuestions.filter" [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>
        </div>
        <button class="btn-submit-form" mat-raised-button color="primary" (click)="closeDialog()">KAPAT</button>
    </div>
</div>
