import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-cache',
  templateUrl: './cache.component.html',
  styles: [
  ]
})
export class CacheComponent implements OnInit {

  constructor(private api: ApiService) { }
  selectedType = '';
  cacheTypes = [
    {
      'label': 'Sınav Türleri',
      'name': 'ExamType'
    },
    {
      'label': 'Yayınlar',
      'name': 'Publication'
    },
    {
      'label': 'Sorular',
      'name': 'Question'
    },
    {
      'label': 'Sınav Soruları',
      'name': 'QuizSlot'
    },
    {
      'label': 'Konular',
      'name': 'Subject'
    },
    {
      'label': 'Kullanıcılar',
      'name': 'User'
    },
    {
      'label': 'Tüm Önbellek',
      'name': 'ASYNC'
    }
  ]
  ngOnInit(): void {
  }

  clearCache() {
    if(!this.selectedType) return alert('Lütfen hangi önbelleği temizlemek istediğinizi seçin!');
    let body = { cache: this.selectedType}
    this.api.post('/api/v1/cache', body).subscribe( data => {
      alert('Seçili önbellek temizlendi!')
    })
  }

}
