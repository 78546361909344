<mat-progress-bar mode="query" *ngIf="submitted"></mat-progress-bar>
<div class="main-container">
    <mat-card class="login-card">
        <mat-card-header>
            <mat-card-title>Giriş Yap</mat-card-title>
        </mat-card-header>
        <mat-divider></mat-divider>
        <mat-card-content>
            <form class="login-form" [formGroup]="form" (ngSubmit)="onSubmit()">
                <mat-form-field appearance="outline">
                    <mat-label>E-Posta</mat-label>
                    <input formControlName="email" matInput>
                    <mat-icon matSuffix>email</mat-icon>
                    <mat-error>
                        {{emailError}}
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Şifre</mat-label>
                    <input type="password" formControlName="password" matInput>
                    <mat-icon matSuffix>vpn_key</mat-icon>
                    <mat-error>
                        {{passwordError}}
                    </mat-error>
                </mat-form-field>
                <button [disabled]="submitted" mat-raised-button color="primary">Giriş</button>
            </form>
        </mat-card-content>
    </mat-card>
</div>
