import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { menu } from '../../settings/dashboard.settings'
@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styles: []
})
export class SidenavComponent implements OnInit {

  constructor(private api: ApiService, private router: Router) { }
  menu: any = menu;
  active: any;
  ngOnInit(): void {
    
  }

  logout() {
    var r = confirm("Çıkış yapmak istediğinize emin misiniz ?");
    if (r == true) {
      this.api.post('/api/v1/user/logout', {}).subscribe( data => {
        localStorage.removeItem("cmsToken");
        this.router.navigate(['login']);
      }, err => {
        localStorage.removeItem("cmsToken");
        this.router.navigate(['login']);
      })
    } else {
      return
    }
  }

}
