import { Component, HostListener, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-bulk-questions',
  templateUrl: './bulk-questions.component.html',
  styles: [
  ]
})
export class BulkQuestionsComponent implements OnInit {
  modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote', 'code-block'],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      ['clean'],
    ]
  };
  singleQuestion: any;
  multiQuestion: any;
  parentQuestion: any;
  childQuestion: any;
  questionsToAdd: any;
  examTypes: any;
  categories: any;
  subjects: any;
  submitted = false;
  selectedExamType: any = null;
  selectedCategory: any = null;
  selectedSubject: any = null;
  selectedSubSubject: any = null;
  selectedYear: any = null;
  someInvalid = false;
  someChecked = false;
  parentQuestionObject: any;
  choices = ["A","B","C","D","E"];
  shifPressing = false;
  constructor(private api: ApiService, private router: Router) { }
  @HostListener('document:keydown.shift', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.shifPressing = true;
  }
  @HostListener('document:keyup.shift', ['$event']) onKeyupHandler(event: KeyboardEvent) {
    this.shifPressing = false;
  }
  ngOnInit(): void {
    this.questionsToAdd = [];
    this.getDataFromAPI('/api/v1/exam-type/all').subscribe(data => {
      this.examTypes = data;
    });
    this.getDataFromAPI('/api/v1/question-category/all').subscribe(data => {
      this.categories = data;
    });
    this.getDataFromAPI('/api/v1/subject/all').subscribe(data => {
      this.subjects = data;
    });
  }

  addSingleQuestion() {
    this.questionsToAdd = [];
    let text = this.singleQuestion.replace(/&nbsp;/g, ' ');
    let question = this.parseQuestions('single', text);
    this.questionsToAdd.push(question);
  }

  addMultiQuestions() {
    this.questionsToAdd = [];
    let text = this.multiQuestion.replace(/&nbsp;/g, ' ');
    let question = this.parseQuestions('multi', text);
    this.questionsToAdd = question;
  }

  addWithParent() {
    this.questionsToAdd = [];
    let text = this.childQuestion.replace(/&nbsp;/g, ' ');
    let question = this.parseQuestions('multi', text);
    this.parentQuestionObject = {
      questionText: this.parentQuestion,
      qtype: 'parent'
    }
    this.questionsToAdd = question;

  }

  parseQuestions(type, text) {
    let array = text.split('#');
      let questions = []
      array.forEach(function(item){
        let res = this.parseOne(item)
        if(res.error) return res;
        questions.push(res)
      }.bind(this))
      if(array.length !== questions.length) {
        alert("Sorulardan bir veya birkaçı hatalı!");
        return { error: "Hata!"}
      } else {
        return questions
      }
  }

  parseOne(text) {
    let options = ["A","B","C","D","E"];
    let positions = [];
    let question = '';
    let answers = [];
    let cleanHtml = text.replace(/<[^>]*>/g, '');
    options.forEach( function(item) {
      if(text.search('' + item +'\\)') > 0) positions.push(text.search('' + item +'\\)'));
    })
    if(positions.length < 5) return {error: 'Hatalı soru formatı!'}
    positions.forEach( function(position, index) {
      if(index === 0) {
        question = text.substring(0,position);
        question = this.prettyQuestion(question);
      }
      let answer = text.substring(position + 2, positions[index + 1]).replace(/<[^>]*>/g, '').replace('\*', '').trim();
      if(cleanHtml.search('\\*' + options[index] +'\\)') > 0) {
        answers.push({answer: answer, correct: true});
      } else {
        answers.push({answer: answer, correct: false});
      }
    }.bind(this));

    if(!question || answers.length !== options.length) return {error: 'Hatalı soru formatı!'}

    return {
      qtype: 'multichoice',
      questionText: question,
      answers: answers
    }
  }

  prettyQuestion(question) {
    let index;
    if(question.slice(0,2) === '</') { // If starting with closing html tag check 3 times and delete
      index = question.indexOf('>');
      if(index > 0) {
        question = question.substr(index + 1, question.length);
        if(question.slice(0,2) === '</') {
          index = question.indexOf('>');
          if(index > 0) {
            question = question.substr(index + 1, question.length);
            if(question.slice(0,2) === '</') {
              index = question.indexOf('>');
              if(index > 0) {
                question = question.substr(index + 1, question.length);
              }
            }
          }
        }
      }
    }
    if(question.slice(question.length - 1, question.length) === '*') { // Check if question ends with *
      question = question.substr(0, question.length - 1);
    }
    question = question.replace(/<p><br><\/p>/g, ''); // Check for empty spaces
    question = question.replace('<br>','<br/>');
    return question;
  }


  saveQuestions() {
    if(this.parentQuestion) {
      this.saveWithParent();
    } else {
      this.saveWithoutParent();
    }
  }

  saveWithParent() {
    if(this.checkIfValid()) {
      // Add parent question first
      let parentBody = this.parentQuestionObject;
      this.api.post('/api/v1/question/add', parentBody).subscribe( data => {
        let parentId = data.id;
        this.questionsToAdd.forEach(element => {
          element.parent = parentId;
        });
        let body = {
          questions: this.questionsToAdd
        }
        this.api.post('/api/v1/question/bulk-add', body).subscribe( data => {
          alert('Tüm sorular eklenmiştir!');
          this.router.navigate(['/list/question']);
          //console.log(data);
        }, err => {
          console.log(err);
        })
      });
    } else {
      this.someInvalid = true;
      alert ('Lütfen kırmızı ile işaretli olan soruları kontrol edin!');
    }
  }

  saveWithoutParent() {
    if(this.checkIfValid()) {
      let body = {
        questions: this.questionsToAdd
      }
      this.api.post('/api/v1/question/bulk-add', body).subscribe( data => {
        alert('Tüm sorular eklenmiştir!');
        this.router.navigate(['/list/question']);
        //console.log(data);
      }, err => {
        console.log(err);
      })
    } else {
      this.someInvalid = true;
      alert ('Lütfen kırmızı ile işaretli olan soruları kontrol edin!');
    }
  }

  checkIfValid() {
    let valid = true;
    this.questionsToAdd.forEach( item => {
      if(!item.isValid) valid = false;
    })
    if(this.parentQuestion) {
      if(!this.parentQuestionObject.isValid) valid = false;
    }
    return valid;
  }


  applyChanges() {

    if(this.parentQuestion) {
      if(this.parentQuestionObject.checked) {
        if(!this.parentQuestionObject.year) {
          this.parentQuestionObject.year = this.selectedYear ? this.selectedYear : null;
        }
        if(!this.parentQuestionObject.category) {
          this.parentQuestionObject.category = this.selectedCategory?.id ? this.selectedCategory?.id : null;
          this.parentQuestionObject.categoryName = this.selectedCategory?.name ? this.selectedCategory?.name : null;
        }
        if(!this.parentQuestionObject.examType) {
          this.parentQuestionObject.examType = this.selectedExamType?.id ? this.selectedExamType?.id : null;
          this.parentQuestionObject.examTypeName =  this.selectedExamType?.name ? this.selectedExamType?.name : null;
        }
        if(!this.parentQuestionObject.subject) {
          this.parentQuestionObject.subject = this.selectedSubject?.id ? this.selectedSubject?.id : null;
          this.parentQuestionObject.subjectName = this.selectedSubject?.name ? this.selectedSubject?.name : null;
        }
      }
      if(this.parentQuestionObject.category && this.parentQuestionObject.subject && this.parentQuestionObject.examType) {
        this.parentQuestionObject.isValid = true;
      } else {
        this.parentQuestionObject.isValid = false;
      }
    }

    this.questionsToAdd.forEach( item => {
      if(item.checked) {
          item.year = this.selectedYear ? this.selectedYear : null;
          item.category = this.selectedCategory?.id ? this.selectedCategory?.id : null;
          item.categoryName = this.selectedCategory?.name ? this.selectedCategory?.name : null;
          item.examType = this.selectedExamType?.id ? this.selectedExamType?.id : null;
          item.examTypeName =  this.selectedExamType?.name ? this.selectedExamType?.name : null;
          item.subject = this.selectedSubject?.id ? this.selectedSubject?.id : null;
          item.subjectName = this.selectedSubject?.name ? this.selectedSubject?.name : null;
          item.parentSubjectName = this.selectedSubject?.name ? this.selectedSubject?.name : null;
          if(this.selectedSubSubject) {
            item.subject = this.selectedSubSubject?.id ? this.selectedSubSubject?.id : null;
            item.subjectName = this.selectedSubSubject?.name ? this.selectedSubSubject?.name : null;
          } else {
            item.subject = this.selectedSubject?.id ? this.selectedSubject?.id : null;
            item.subjectName = this.selectedSubject?.name ? this.selectedSubject?.name : null;
          }
      }
      if(item.category && item.subject && item.examType) {
        item.isValid = true;
      } else {
        item.isValid = false;
      }
    })

    this.unSelectAll();
  }

  getDataFromAPI(endpoint): Observable<any> {
    return this.api.get(endpoint);
  }

  selectAll(event) {
    if(event.checked) {
      this.questionsToAdd.forEach( item => {
        item.checked = true
      })
    } else {
      this.questionsToAdd.forEach( item => {
        item.checked = false
      })
    }
  }

  unSelectAll() {
    this.someChecked = false;
    this.questionsToAdd.forEach( item => {
      item.checked = false
    })
  }

  checkSelections(event,index) {
    console.log('checking' + index);
    this.someChecked = false;
    if(this.shifPressing) {
      let firstSelected = this.questionsToAdd.findIndex( question => question.checked );
      if(firstSelected < index) {
        for( let i = firstSelected; i < index; i++) {
          this.questionsToAdd[i].checked = true
        }
      }
      this.questionsToAdd.forEach( item => {
        if(item.checked) {
          this.someChecked = true;
        }
      })
    } else {
      this.questionsToAdd.forEach( item => {
        if(item.checked) {
          this.someChecked = true;
        }
      })
    }

  }


}
