const examTypesModel = [
    {
        name: "name",
        label: "Başlık *",
        type: "text"
    }
]

const examTypesList = {
    title: "Tüm Sınav Türleri",
    canCreate: true,
    model: examTypesModel,
    displayedColumns: [
        {
            name: "name",
            label: "Başlık"
        }
    ]
}

export { examTypesList }