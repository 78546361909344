import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { listSettings } from '../../settings/dashboard.settings';

@Component({
  selector: 'app-emailer',
  templateUrl: './emailer.component.html',
  styles: [
  ]
})
export class EmailerComponent implements OnInit {
  loading: Boolean = true;
  data = new MatTableDataSource([]);
  selection = new SelectionModel(true, []);
  matColumns = [];
  displayedColumns = [];
  totalDataCount = 0;
  page = 0;
  pageSize = 999998;
  settings: any;
  mailScreen = false;
  emailBody: any;
  emailSubject: any;
  mailsToSend: any = [];
  constructor(private route:ActivatedRoute, private router: Router, private api: ApiService) { }

  ngOnInit(): void {
    this.settings = listSettings['user'];
    this.getDisplayedColumns();
    this.initDataFromAPI();
  }

  getDisplayedColumns() {
    this.matColumns = [];
    this.displayedColumns = [{
      name: "select",
      label: "Seçim",
      type: "checkboxControl"
  }];
    this.displayedColumns = [...this.displayedColumns, ...this.settings.displayedColumns];
    this.matColumns = this.displayedColumns.map(col => col.name);
  }

  initDataFromAPI() {
    this.loading = true;
    this.api.get('/api/v1/user/search?page=0&pageSize=999998').subscribe(data => {
      this.data = new MatTableDataSource(data.rows);
      this.selection = new SelectionModel(true, data);
      this.loading = false;
    }, error => {
      if(error.status == 401) {
        localStorage.setItem("cmsToken", "");
        this.router.navigate(['login']);
      }
    })
  }

  openMailScreen(option) {
    this.loading = true;
    this.mailScreen = true;
    if(option === 'all') {
      this.api.get('/api/v1/user/search?page=0&pageSize=999998').subscribe(data => {
        data.rows.forEach(item => {
          if(item.email) {
            this.mailsToSend.push(item.email);
          }
        })
        this.loading = false;
      })
    } else {
      if(this.selection.selected.length > 0) {
        this.selection.selected.forEach(item => {
          if(item.email) {
            this.mailsToSend.push(item.email);
          }
        })
      }
      this.loading = false;
    }
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.data.filter = filterValue.trim().toLowerCase();
  }

  clickRow(row) {
    //console.log(row);
    // console.log(this.selection)
  }

  selecitonChanged() {
    // console.log(this.selection);
  }

  isAllSelected() {
    const numSelected = this.selection?.selected.length;
    const numRows = this.pageSize;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.data.data.forEach(row => this.selection.select(row));
  }

  sendEmail() {
    console.log(this.mailsToSend);
    alert('Mailler gönderiliyor');
    this.api.post('/api/v1/email',{
      'emails':this.mailsToSend,
      'emailSubject':this.emailSubject,
      'emailBody':this.emailBody
    }).subscribe(data => {
      alert('Mailler gönderildi');
    })
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection?.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

}
