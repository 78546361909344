import { userList } from './user.settings';
import { questionList } from './question.settings';
import { categoryList } from './category.settings';
import { productList } from './product.settings';
import { subCategoryList } from './sub-category.settings';
import { reportList } from './report.settings';
import { publicationList } from './publication.settings';
import { subjectList } from './subject.settings';
import { examTypesList } from './exam-type.settings';
import { quizList } from './quiz.settings';
import {packageList} from "./package.settings";
import {quizCategoryList} from "./quiz-category.settings";
import {purchaseList} from './purchase.settings';

const menu = [
    {
        label: 'Soru',
        icon: 'question_answer',
        path: '/list/question'
    },
    {
        label: 'Quiz',
        icon: 'rule_folder',
        path: '/list/quiz'
    },
    {
        label: 'Kullanıcılar',
        icon: 'face',
        path: '/list/user'
    },
    {
      label: 'Mağaza',
      icon: 'shopping_cart',
      subMenu: [
        {
          label: 'Ürünler',
          icon: 'shopping_bag',
          path: '/list/product'
        },
        {
          label: 'Yayın',
          icon: 'menu_book',
          path: '/list/publication'
        },
        {
          label: 'Paketler',
          icon: 'inventory_2',
          path: '/list/package'
        },
        {
          label: 'Paket Ayarları',
          icon: 'list',
          path: '/package-slots'
        },
        {
          label: 'Kullanıcı Paket Atama',
          icon: 'people',
          path: '/package-users'
        },
        {
          label: 'Siparişler',
          icon: 'shopping_bag',
          path: '/list/purchase'
        }
      ]
    },
    {
        label: 'Ayarlar',
        icon: 'settings',
        subMenu: [
            {
                label: 'Sınav Türü',
                icon: 'topic',
                path: '/list/exam-type'
            },
          {
            label: 'Quiz Kategorisi',
            icon: 'rule_folder',
            path: '/list/quiz-category'
          },
            {
                label: 'Konu',
                icon: 'subject',
                path: '/list/subject'
            },
            {
                label: 'Soru Kategorisi',
                icon: 'category',
                path: '/list/question-category'
            },
            {
                label: 'Toplu Email',
                icon: 'forward_to_inbox',
                path: '/emailer'
            },
            {
                label: 'Önbellek Temizleme',
                icon: 'auto_delete',
                path: '/cache'
            }
        ]
    }
];

const listSettings = {
    question: questionList,
    'question-category': categoryList,
    'exam-type': examTypesList,
    publication: publicationList,
    subject: subjectList,
    user: userList,
    quiz: quizList,
    'quiz-category': quizCategoryList,
    package: packageList,
    product: productList,
    purchase: purchaseList
};

export { menu, listSettings };
