import { Component, OnInit } from '@angular/core';
import {ApiService} from '../../services/api.service';
import {SelectionModel} from '@angular/cdk/collections';

@Component({
  selector: 'app-package-users',
  templateUrl: './package-users.component.html',
  styles: [
  ]
})
export class PackageUsersComponent implements OnInit {
  page = 0;
  pageSize = 10;
  userData: any;
  totalUserCount: any;
  packages: any;
  products: any;
  selectedPackage: any;
  selectedProduct: any;
  displayedColumns: string[] = ['select', 'name', 'surname', 'email'];
  selection = new SelectionModel<any>(true, []);
  selectedUsers = [];
  filterEmail: any;
  filterName: any;
  filterSurname: any;
  constructor(private api: ApiService) { }

  ngOnInit(): void {
    this.filterUsers();
    this.getPackages();
    this.getProducts();
  }

  filterUsers(): void {
    let url = `/api/v1/user/search?page=${ this.page }&pageSize=${ this.pageSize }`;
    if (this.filterEmail) {
      url += '&email=' + this.filterEmail;
    }
    if (this.filterName) {
      url += '&name=' + this.filterName;
    }
    if (this.filterSurname) {
      url += '&surname=' + this.filterSurname;
    }
    this.api.get(url).subscribe( data => {
      this.userData = data.rows;
      this.totalUserCount = data.count;
    });
  }

  getPackages(): void {
    this.api.get('/api/v1/package/all').subscribe( data => {
      this.packages = data;
    });
  }

  getProducts(): void {
    this.api.get('/api/v1/product/all').subscribe( data => {
      this.products = data;
    });
  }

  changePackage(event): void {
    this.selectedProduct = null;
    this.selectedPackage = event.value;
  }

  changeProduct(event): void {
    this.selectedPackage = null;
    this.selectedProduct = event.value;
  }

  pageChange(event): void {
    this.page = event.pageIndex;
    this.pageSize = event.pageSize;
    this.filterUsers();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.userData.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(): void {
    this.isAllSelected() ?
      this.selection.clear() :
      this.userData.forEach(row => this.selection.select(row));
  }

  getSelected(row): void {
    this.selection.toggle(row);
    this.selectedUsers = this.selection.selected;
  }

  removeUser(user): void {
    const index = this.selectedUsers.indexOf(user);
    if (index >= 0) {
      this.selectedUsers.splice(index, 1);
    }
    this.selection.clear();
    this.selectedUsers.forEach(row => this.selection.select(row));
  }

  removeAllUsers(): void {
    this.selection.clear();
    this.selectedUsers = [];
  }

  addPackageToUsers(): void {
    const users = this.selectedUsers.map( x => x.id);
    const packageName = this.packages.find(x => x.id === this.selectedPackage);
    const r = confirm(`"${packageName.name}" isimli paket seçtiğiniz "${users.length}" kullanıcının hesabına tanımlanacaktır onaylıyor musunuz?`);
    if (r === true) {
      const body = {
        users,
        packageId: this.selectedPackage,
        count: 1
      };
      this.api.post('/api/v1/purchase/bulk-package-assign', body).subscribe( data => {
        this.selection.clear();
        this.selectedUsers = [];
      });
    }
  }

  addProductToUsers(): void {
    const users = this.selectedUsers.map( x => x.id);
    const productName = this.products.find(x => x.id === this.selectedProduct);
    const r = confirm(`"${productName.name}" isimli ürün seçtiğiniz "${users.length}" kullanıcının hesabına tanımlanacaktır onaylıyor musunuz?`);
    if (r === true) {
      const body = {
        users,
        productId: this.selectedProduct,
        count: 1
      };
      this.api.post('/api/v1/purchase/bulk-product-assign', body).subscribe( data => {
        this.selection.clear();
        this.selectedUsers = [];
      });
    }
  }

}
