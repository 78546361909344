const quizCategoryModel = [
  {
    name: 'name',
    label: 'Başlık *',
    type: 'text'
  }
];

const quizCategoryList = {
  title: 'Tüm Sınav Türleri',
  canCreate: true,
  model: quizCategoryModel,
  displayedColumns: [
    {
      name: 'name',
      label: 'Başlık'
    }
  ]
};

export { quizCategoryList };
