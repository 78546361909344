import { Component, OnInit } from '@angular/core';
import {ApiService} from "../../services/api.service";

@Component({
  selector: 'app-package-slots',
  templateUrl: './package-slots.component.html',
  styles: [
  ]
})
export class PackageSlotsComponent implements OnInit {
  selectedPackage: any;
  packages: any;
  products: any;
  packageProducts: any;
  selectedOnce = false;
  addingProduct = false;
  productsToAdd = [];
  constructor(private api: ApiService) { }

  ngOnInit(): void {
    this.getAllPackages();
    this.getAllProducts();
  }

  getAllPackages(): void {
    this.api.get('/api/v1/package/all').subscribe( data => {
      this.packages = data;
    });
  }

  getAllProducts(): void {
    this.api.get('/api/v1/product/all').subscribe( data => {
      this.products = data;
    });
  }

  changePackage(event): void {
    this.packageProducts = [];
    this.selectedOnce = true;
    this.selectedPackage = event.value;
    this.getSelectedPackage();
  }

  getSelectedPackage(): void {
    this.api.get('/api/v1/package-slot/products/' + this.selectedPackage).subscribe( data => {
      this.packageProducts = data;
    });
  }

  changeProduct(event, index): void {
    this.productsToAdd[index].productId = event.value;
  }

  changeCount(event, index): void {
    this.productsToAdd[index].count = event.target.value;
  }

  addSlot(): void {
    this.productsToAdd.push({
      packageId: this.selectedPackage,
      productId: null,
      count: null
    });
  }

  save(): void {
    const body = {
      packageSlots: this.productsToAdd
    };
    this.api.post('/api/v1/package-slot/bulk-add', body).subscribe( data => {
      alert('Ürünler eklendi!');
      this.getSelectedPackage();
    });
  }

  deletePackage(id): void {
    const body = {
      packageId: this.selectedPackage,
      productId: id
    };
    this.api.post('/api/v1/package-slot/delete-product', body).subscribe( data => {
      alert('Ürün silindi!');
      this.getSelectedPackage();
    });
  }

}
