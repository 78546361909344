const questionModel = [
    {
        name: 'qtype',
        label: 'Soru Tipi *',
        type: "select",
        options: [
            {
                name: "parent",
                label: "Ana Soru"
            },
            {
                name: "multichoice",
                label: "Çoktan Seçmeli"
            }
        ],
        noedit: true
    },
    {
        name: "examType",
        label: "Sınav Türü *",
        type: "ref",
        endpoint: "/api/v1/exam-type/all",
        options: []
    },
    {
        name: "category",
        label: "Kategori *",
        type: "ref",
        endpoint: "/api/v1/question-category/all",
        options: []
    },
    {
        name: "subject",
        label: "Konu *",
        type: "ref",
        endpoint: "/api/v1/subject/all",
        options: []
    },
    {
        name: "year",
        label: "Soru Yılı *",
        type: "number",
        min: 1900,
        max: 2100
    },
    {
        name: 'questionText',
        label: 'Soru Metni *',
        type: 'html'
    },
    {
        name: 'answers',
        label: 'Cevaplar',
        type: 'answers',
        required: false
    }
]

const questionList = {
    title: "Tüm Sorular",
    canCreate: true,
    model: questionModel,
    displayedColumns: [
        {
            name: "select",
            label: "Seçim",
            type: "checkboxControl"
        },
        {
            name: "questionText",
            label: "Soru",
            type: "html"
        },
        {
          name: "edit",
          label: "Düzenle"
        }
    ],
    filter: ['qtype','examType','category','subject','questionText','year'],
    bulkEdit: ['examType','category','subject','year']
}

export { questionList }
