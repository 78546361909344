const userModel = [
    {
        name: 'name',
        label: 'Adı *',
        type: 'text'
    },
    {
        name: 'surname',
        label: 'Soyadı *',
        type: 'text'
    },
    {
        name: 'email',
        label: 'E-Posta *',
        type: 'text',
        validateEmail: true
    },
    {
      name: 'type',
      label: 'Kullanıcı Türü',
      type: 'select',
      options: [
        {
          name: '0',
          label: 'Öğrenci'
        },
        {
          name: '2',
          label: 'Öğretmen'
        },
        {
          name: '1',
          label: 'Yönetici'
        }
      ]
    },
    {
        name: 'phone',
        label: 'Telefon *',
        type: 'text'
    },
    {
        name: 'password',
        label: 'Şifre *',
        type: 'text'
    },
    {
        name: 'username',
        label: 'Kullanıcı Adı *',
        type: 'text'
    },
    {
        name: 'classroom',
        label: 'Sınıf *',
        type: 'text'
    },
    {
        name: 'schoolName',
        label: 'Okul *',
        type: 'text'
    },
    {
        name: 'emailVerified',
        label: 'Email Doğrulandı',
        type: 'toggle',
        required: false
    },
    {
        name: 'isSubscribe',
        label: 'E-Posta İzni',
        type: 'toggle',
        required: false
    },
];

const userList = {
    title: 'Tüm Kullanıcılar',
    canCreate: true,
    model: userModel,
    displayedColumns: [
        {
            name: 'email',
            label: 'E-Posta Adresi'
        },
        {
            name: 'name',
            label: 'Adı'
        },
        {
            name: 'surname',
            label: 'Soyadı'
        }
    ],
    filter: ['email']
};

export { userList };
