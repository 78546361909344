const productModel = [
  {
    name: 'name',
    label: 'Başlık *',
    type: 'text'
  },
  {
    name: 'coverImage',
    label: 'Ana Görsel',
    type: 'text',
    required: false
  },
  {
    name: 'productType',
    label: 'Türü *',
    type: 'select',
    options: [
      {
        name: 'publication',
        label: 'Publication'
      },
      {
        name: 'quiz',
        label: 'Quiz'
      }
    ]
  },
  {
    name: 'price',
    label: 'Fiyat *',
    type: 'number'
  },
  {
    name: 'reducedPrice',
    label: 'İndirimli Fiyat',
    type: 'number',
    required: false
  },
  {
    name: 'star',
    label: 'Yıldız',
    type: 'number',
    required: false
  },
  {
    name: 'description',
    label: 'Açıklama',
    type: 'text',
    required: false
  },
  {
    name: 'order',
    label: 'Sıra',
    type: 'number',
    required: false
  },
  {
    name: 'publicationId',
    label: 'Yayın',
    type: 'ref',
    endpoint: '/api/v1/publication/all',
    options: [],
    required: false
  },
  {
    name: 'quizId',
    label: 'Sınav',
    type: 'ref',
    endpoint: '/api/v1/quiz/all',
    options: [],
    required: false
  },
  {
    name: 'quizCategoryId',
    label: 'Quiz Kategorisi',
    type: 'ref',
    endpoint: '/api/v1/quiz-category/all',
    options: [],
    required: false
  },
];

const productList = {
  title: 'Tüm Ürünler',
  canCreate: true,
  model: productModel,
  displayedColumns: [
    {
      name: 'name',
      label: 'Başlık'
    }
  ]
};

export { productList };
