<ng-container>
  <mat-toolbar color="primary" >
    <mat-toolbar-row>
      <span>Paket Ayarları</span>
      <span class="toolbar-spacer"></span>
      <div class="toolbar-actions">
        <button [routerLink]="'/list/package'" mat-button>Tüm Paketler</button>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
  <div class="list-container">
    <b>Bir paket seçin:</b><br>
    <mat-form-field appearance="outline">
      <mat-label>Paket</mat-label>
      <mat-select (selectionChange)="changePackage($event)">
        <mat-option *ngFor="let package of packages" [value]="package.id">
          {{package.name}}
        </mat-option>
      </mat-select>
      <mat-error>Bu alanın dolurulması zorunludur</mat-error>
    </mat-form-field>
    <div class="package-slots" *ngIf="selectedPackage">
      <p><b>Pakete Ekli Ürünler:</b></p>
      <mat-list>
        <mat-list-item *ngFor="let slot of packageProducts" class="package-item">
          {{slot.product.name}} <span *ngIf="slot.count" style="color:#3f50b5;"> &nbsp; <b> ({{slot.count}}) </b></span> <button mat-button color="warn" (click)="deletePackage(slot.product.id)">Ürünü Sil -</button>
        </mat-list-item>
      </mat-list>
      <div class="add-product" *ngFor="let productsAdd of productsToAdd; let i = index">
        <mat-form-field appearance="outline">
          <mat-select (selectionChange)="changeProduct($event, i)">
            <mat-option *ngFor="let product of products" [value]="product.id">
              {{product.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Miktar</mat-label>
            <input type="number" (input)="changeCount($event, i)" matInput>
        </mat-form-field>
      </div>
      <p><button mat-button color="primary" (click)="addSlot()">Ürün Ekle +</button></p>
      <p><button mat-raised-button color="primary" (click)="save()">Kaydet</button></p>
    </div>
  </div>
</ng-container>
