<ng-container>
  <mat-toolbar color="primary" >
    <mat-toolbar-row>
      <span>Kullanıcı Paket Atama</span>
      <span class="toolbar-spacer"></span>
      <div class="toolbar-actions">
        <button [routerLink]="'/list/package'" mat-button>Tüm Paketler</button>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
  <div class="list-container">
    <div class="list-actions" style="display: flex">
      <div class="">
        <p><b>Eklenecek Paketi Seçin:</b></p>
        <mat-form-field appearance="outline">
          <mat-label>Paket</mat-label>
          <mat-select (selectionChange)="changePackage($event)" [(ngModel)]="selectedPackage">
            <mat-option *ngFor="let package of packages" [value]="package.id">
              {{package.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="">
        <p><b>Eklenecek Ürünü Seçin:</b></p>
        <mat-form-field appearance="outline">
          <mat-label>Ürün</mat-label>
          <mat-select (selectionChange)="changeProduct($event)" [(ngModel)]="selectedProduct">
            <mat-option *ngFor="let product of products" [value]="product.id">
              {{product.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <p><b>Seçili Kullanıcılar:</b></p>
    <div class="selected-users">
      <mat-chip-list aria-label="Fish selection">
        <mat-chip color="primary" [removable]="true" (removed)="removeUser(user)" selected *ngFor="let user of selectedUsers">
          {{user.email}}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
      </mat-chip-list>
      <br><br>
      <p><button *ngIf="selectedUsers.length > 0 && selectedPackage" mat-raised-button color="primary" (click)="addPackageToUsers()">Paketi Ekle</button></p>
      <p><button *ngIf="selectedUsers.length > 0 && selectedProduct" mat-raised-button color="primary" (click)="addProductToUsers()">Ürünü Ekle</button></p>
      <p style="text-align: right;"><button *ngIf="selectedUsers.length > 0" mat-raised-button color="warn" (click)="removeAllUsers()">Seçili Kullanıcıları Temizle</button></p>
    </div>
    <p><b>Kullanıcıları Seçin:</b></p>
    <p>
      <mat-form-field appearance="fill">
        <mat-label>Email</mat-label>
        <input [(ngModel)]="filterEmail" (change)="filterUsers()" matInput>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Ad</mat-label>
        <input [(ngModel)]="filterName" (change)="filterUsers()" matInput>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Soyad</mat-label>
        <input [(ngModel)]="filterSurname" (change)="filterUsers()" matInput>
      </mat-form-field>
    </p>
    <div class="users-table" *ngIf="userData">
      <table mat-table [dataSource]="userData" class="mat-elevation-z8">

        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? masterToggle() : null"
                          [checked]="selection.hasValue() && isAllSelected()"
                          [indeterminate]="selection.hasValue() && !isAllSelected()">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event ? $event.stopPropagation() : null"
                          (change)="$event ? getSelected(row) : null"
                          [checked]="selection.isSelected(row)">
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> Adı </th>
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <ng-container matColumnDef="surname">
          <th mat-header-cell *matHeaderCellDef> Soyadı </th>
          <td mat-cell *matCellDef="let element"> {{element.surname}} </td>
        </ng-container>

        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef> Email </th>
          <td mat-cell *matCellDef="let element"> {{element.email}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <mat-paginator (page)="pageChange($event)" [length]="totalUserCount" [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>
    </div>
  </div>
</ng-container>
