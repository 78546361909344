<ng-container *ngIf="settings">
    <mat-toolbar color="primary" >
        <mat-toolbar-row>
        <span>Yeni Oluştur</span>
        <span class="toolbar-spacer"></span>
        <div class="toolbar-actions">
            <button [routerLink]="'/list/' + slug" mat-button>{{settings.title}}</button>
        </div>
        </mat-toolbar-row>
    </mat-toolbar>
    <div class="create-container" *ngIf="formData.length > 0">
        <div class="mat-elevation-z8">
            <form *ngIf="formGroup" [formGroup]="formGroup" (ngSubmit)="onSubmit()" class="create-form">
                <div *ngIf="slug === 'product'">
                  <section>
                    <mat-checkbox (change)="toggleQuizCount($event)">Quiz Hakkı Tanımlıyorum</mat-checkbox>
                  </section>
                </div>
                <ng-container *ngFor="let field of formData">
                    <mat-form-field appearance="outline" *ngIf="field.type === 'text'">
                        <mat-label>{{field.label}}</mat-label>
                        <input formControlName={{field.name}} matInput>
                        <mat-error *ngIf="hasError(field.name, 'required')">Bu alanın dolurulması zorunludur</mat-error>
                        <mat-error *ngIf="hasError(field.name, 'pattern')">Lütfen geçerli bir mail adresi giriniz</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" *ngIf="field.type === 'number'">
                        <mat-label>{{field.label}}</mat-label>
                        <input type="number" formControlName={{field.name}} matInput>
                        <mat-error *ngIf="hasError(field.name, 'required')">Bu alanın dolurulması zorunludur</mat-error>
                        <mat-error *ngIf="hasError(field.name, 'min')">Lütfen geçerli bir tarih ( 1900 - 2100 ) giriniz</mat-error>
                        <mat-error *ngIf="hasError(field.name, 'max')">Lütfen geçerli bir tarih ( 1900 - 2100 ) giriniz</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" *ngIf="field.type === 'select'">
                        <mat-label>{{field.label}}</mat-label>
                        <mat-select formControlName={{field.name}}>
                          <mat-option *ngFor="let option of field.options" [value]="option.name">
                            {{option.label}}
                          </mat-option>
                        </mat-select>
                        <mat-error *ngIf="hasError(field.name, 'required')">Bu alanın dolurulması zorunludur</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" *ngIf="field.type === 'textarea'">
                        <mat-label>{{field.label}}</mat-label>
                        <textarea formControlName={{field.name}} matInput></textarea>
                        <mat-error *ngIf="hasError(field.name, 'required')">Bu alanın dolurulması zorunludur</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" *ngIf="field.type === 'image'">
                        <img class="uploadedimage" src={{formGroup.value[field.name]}} *ngIf="formGroup.value[field.name]">
                        <input formControlName={{field.name}} matInput [hidden]="true">
                        <button type="button" mat-raised-button (click)="fileUpload.click();this.selectedField = field.name;this.uploadType = 'single'">{{field.label}}</button>
                    </mat-form-field>
                    <mat-form-field appearance="outline" *ngIf="field.type === 'multiimage'">
                        <ng-container *ngFor="let image of formGroup.value[field.name]; let i = index">
                            <img class="uploadedimage" src={{image}} (click)="deleteImage(field.name, i)">
                        </ng-container>
                        <input formControlName={{field.name}} matInput [hidden]="true">
                        <button type="button" mat-raised-button (click)="fileUpload.click();this.selectedField = field.name;this.uploadType = 'multi'">{{field.label}}</button>
                    </mat-form-field>
                    <div class="custom-form-field" *ngIf="field.type === 'toggle'">
                        <mat-slide-toggle [color]="'primary'" formControlName={{field.name}} matInput>{{field.label}}</mat-slide-toggle>
                    </div>
                    <mat-form-field appearance="outline" *ngIf="field.type === 'datetime'">
                        <mat-label>{{field.label}}</mat-label>
                        <input matInput  (dateChange)="dateChange($event, field)" [ngxMatDatetimePicker]="picker" placeholder="Choose a date" formControlName={{field.name}}>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <ngx-mat-datetime-picker #picker [showSpinners]="true" [showSeconds]="false"
                           [stepHour]="true" [stepMinute]="true" [stepSecond]="false"
                           [touchUi]="true" [color]="'primary'" [enableMeridian]="false"
                           [disableMinute]="false" [hideTime]="false">
                        </ngx-mat-datetime-picker>
                     </mat-form-field>
                    <mat-form-field appearance="outline"
                                    *ngIf="
                                    field.type === 'ref' &&
                                    field.name !== 'subject' &&
                                    (field.name !== 'quizId' || !quizCount) &&
                                    (field.name !== 'publicationId' || !quizCount) &&
                                    (field.name !== 'quizCategoryId' || quizCount)
">
                        <mat-label>{{field.label}}</mat-label>
                        <mat-select formControlName={{field.name}}>
                          <mat-option [value]="null">
                            Seçilmedi
                          </mat-option>
                          <mat-option *ngFor="let option of field.options" [value]="option.id">
                            {{option.name}}
                          </mat-option>
                        </mat-select>
                        <mat-error *ngIf="hasError(field.name, 'required')">Bu alanın dolurulması zorunludur</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" *ngIf="field.type === 'ref' && field.name === 'subject'">
                        <mat-label>Ana Konu</mat-label>
                        <mat-select formControlName="parentSubject">
                            <mat-option [value]="">Seçiniz</mat-option>
                            <ng-container *ngFor="let value of field.options">
                                <mat-option  [value]="value.id" *ngIf="value.parent === null">
                                    {{value.name}}
                                    </mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" *ngIf="field.type === 'ref' && field.name === 'subject' && this.formGroup.value.parentSubject">
                        <mat-label>Konu</mat-label>
                        <mat-select formControlName="subject">
                            <mat-option [value]="">Seçiniz</mat-option>
                            <ng-container *ngFor="let value of field.options">
                                <mat-option  [value]="value.id" *ngIf="value.parent === this.formGroup.value.parentSubject">
                                    {{value.name}}
                                    </mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                    <quill-editor [styles]="{height: '250px'}" *ngIf="field.type === 'html'" [placeholder]="'Soru metnini bu alana giriniz'" [modules]="modules" formControlName={{field.name}}></quill-editor>
                    <ng-container *ngIf="field.type === 'answers' && this.formGroup.value.qtype === 'multichoice'">
                        <div class="answers-container">
                            <p><b>Cevaplar:</b></p>
                            <mat-radio-group>
                                    <div class="answer" *ngFor="let item of answers; let i = index">
                                        <mat-radio-button value={{item.choice}} [checked]="item.correct" (change)="radioChange($event)"></mat-radio-button> {{item.choice}}
                                        <quill-editor [styles]="{height: '60px'}" [placeholder]="'Cevap metnini bu alana giriniz'" [modules]="modules" formControlName={{item?.choice}}></quill-editor>
                                    </div>
                            </mat-radio-group>
                        </div>
                    </ng-container>
                </ng-container>
                <input #fileUpload id="fileUpload" type="file" style="display:none" (change)="handleFileInput($event.target.files)" />
                <button [disabled]="submitted" class="btn-submit-form" mat-raised-button color="primary">Oluştur</button>
            </form>
        </div>
    </div>
</ng-container>
