import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { listSettings } from '../../settings/dashboard.settings';
import { ApiService } from 'src/app/services/api.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { Observable } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styles: []
})
export class ListComponent implements OnInit {
  slug: string;
  settings: any;
  displayedColumns: any;
  matColumns = []
  data = new MatTableDataSource([]);
  loading: Boolean = true;
  filters = [];
  bulkEdits = [];
  filterGroup: any;
  bulkEditGroup: any;
  totalDataCount = 0;
  page = 0;
  pageSize = 10;
  selection = new SelectionModel(true, []);
  detailedFilter = false;
  bulkEdit = false;
  sorting = 'new';
  filterQuiz = '';
  quizzes: any;
  questionCategories: any;
  examTypes: any;
  questionSubjects: any;
  subjects: any;
  yearSelection = '';
  constructor(private route: ActivatedRoute, private router: Router, private api: ApiService) {}
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit(): void {
    this.filters = [];
    this.filterGroup = {};
    this.route.params.subscribe(params => {
      this.data = new MatTableDataSource([]);
      this.selection = new SelectionModel(true, []);
      this.settings = {};
      this.slug = params["slug"];
      if(listSettings[this.slug]) {
        this.settings = listSettings[this.slug];
        this.getDisplayedColumns();
        if(this.slug === 'question') {
          this.initFilteredDataFromAPI();
          this.getQuizesFromAPI();
          this.getQuestionCategories();
          this.getExamTypes();
          this.getSubjects();
        } else if(this.slug === 'user') {
          this.loading = false;
        } else if(this.slug === 'purchase') {
          this.initPurchases();
        } else {
          this.initDataFromAPI();
        }
        this.generateFilters();
        this.generateBulkEdits();
      }
    });
  }
  yearChange(event) {
    if (event.value) {
      this.yearSelection = event.value;
    }
  }
  getQuizesFromAPI() {
    this.api.get('/api/v1/quiz/all').subscribe( res => {
      this.quizzes = res;
    });
  }
  getQuestionCategories() {
    this.api.get('/api/v1/question-category/all').subscribe( res => {
      this.questionCategories = res;
    });
  }
  getExamTypes() {
    this.api.get('/api/v1/exam-type/all').subscribe( res => {
      this.examTypes = res;
    });
  }
  getSubjects() {
    this.api.get('/api/v1/subject/all').subscribe( res => {
      this.questionSubjects = res;
    });
  }
  getQuestionCategoryById(id: string): string {
    if(this.questionCategories) {
      if (!id) { return; }
      const found = this.questionCategories.find(item => item.id === parseInt(id));
      return found.name;
    }
  }
  getExamTypeById(id: string): string {
    if(this.examTypes) {
      if (!id) { return; }
      const found = this.examTypes.find(item => item.id === parseInt(id));
      return found.name;
    }
  }
  getSubjectById(id: string): string {
    if(this.questionSubjects) {
      if (!id) { return; }
      const found = this.questionSubjects.find(item => item.id === parseInt(id));
      return found.name;
    }
  }
  getSubjectParent(id: string): string {
    if(this.questionSubjects) {
      if (!id) { return; }
      const found = this.questionSubjects.find(item => item.id === parseInt(id));
      if (!found.parent) return '';
      const parent = this.questionSubjects.find(item => item.id === parseInt(found.parent));
      return parent.name;
    }
  }
  getDisplayedColumns() {
    this.matColumns = [];
    this.displayedColumns = [];
    this.displayedColumns = this.settings.displayedColumns;
    this.matColumns = this.displayedColumns.map(col => col.name);
  }
  initDataFromAPI() {
    this.loading = true;
    this.api.get('/api/v1/' + this.slug + '/all').subscribe(data => {
      let tableData = [];
      tableData = data;
      this.data = new MatTableDataSource(tableData);
      this.selection = new SelectionModel(true, tableData);
      this.data.paginator = this.paginator;
      this.totalDataCount = tableData.length;
      this.loading = false;
    }, error => {
      if(error.status == 401) {
        localStorage.removeItem("cmsToken");
        this.router.navigate(['login']);
      }
    })
  }
  initPurchases() {
    this.page = 0;
    this.pageSize = 100;
    this.loading = true;
    this.api.get('/api/v1/purchase/all?page=0&pageSize=' + this.pageSize).subscribe(data => {
      this.data = new MatTableDataSource(data.rows);
      this.selection = new SelectionModel(true, data);
      this.data.paginator = this.paginator;
      this.totalDataCount = data.count;
      this.loading = false;
    }, error => {
      if(error.status == 401) {
        localStorage.setItem("cmsToken", "");
        this.router.navigate(['login']);
      }
    })
  }
  initFilteredDataFromAPI() {
    this.page = 0;
    this.pageSize = 100;
    this.loading = true;
    this.api.get('/api/v1/' + this.slug + '/search?page=0&pageSize=' + this.pageSize).subscribe(data => {
      this.data = new MatTableDataSource(data.rows);
      this.selection = new SelectionModel(true, data);
      this.data.paginator = this.paginator;
      this.totalDataCount = data.count;
      this.loading = false;
    }, error => {
      if(error.status == 401) {
        localStorage.setItem("cmsToken", "");
        this.router.navigate(['login']);
      }
    })
  }

  getDataFromAPI(endpoint): Observable<any> {
    return this.api.get(endpoint);
  }

  generateFilters() {
    this.filters = [];
    if(this.settings.filter) {
      let group = {}
      this.settings.filter.forEach( item => {
        group[item] = new FormControl('');
        const obj = this.settings.model.find( model => model.name === item);
        if(obj.type === 'ref') {
          obj.options = [];
          this.getDataFromAPI(obj.endpoint).subscribe(data => {
            obj.options = data;
          });
        }
        this.filters.push(this.settings.model.find( model => model.name === item))
      })
      this.filterGroup = new FormGroup(group);
    }
  }
  changeSorting(): void {
    this.filter();
  }
  changeQuizFilter(): void {
    this.filter();
  }
  generateBulkEdits() {
    this.bulkEdits = [];
    if(this.settings.bulkEdit) {
      let group = {}
      this.settings.bulkEdit.forEach( item => {
        if(item === 'subject') {
          group['parentSubject'] = new FormControl('');
        }
        group[item] = new FormControl('');
        const obj = this.settings.model.find( model => model.name === item);
        if(obj.type === 'ref') {
          obj.options = [];
          this.getDataFromAPI(obj.endpoint).subscribe(data => {
            obj.options = data;
          });
        }
        this.bulkEdits.push(this.settings.model.find( model => model.name === item))
      })
      this.bulkEditGroup = new FormGroup(group);
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.data.filter = filterValue.trim().toLowerCase();
  }

  filter() {
    if(this.filterGroup.value) {
      var queryString = Object.keys(this.filterGroup.value)
      .filter(key => (this.filterGroup.value[key] !== '' && this.filterGroup.value[key] !== undefined && this.filterGroup.value[key] !== null))
      .map(key => key + '=' + this.filterGroup.value[key])
      .join('&');
      let filterUrl = '/api/v1/' + this.slug + '/search?' + queryString;
      if(!queryString) {
        filterUrl = filterUrl + 'page=' + this.page + '&pageSize=' + this.pageSize;
      } else {
        filterUrl = filterUrl + '&page=' + this.page + '&pageSize=' + this.pageSize;
      }
      if (this.sorting === 'alphabetic') {
        filterUrl = filterUrl + '&sort=A-Z';
      }
      if(this.filterQuiz) {
        filterUrl = filterUrl + '&quizId=' + this.filterQuiz;
      }
      if(this.yearSelection) {
        filterUrl = filterUrl + '&yearSort=' + this.yearSelection;
      }
      if(filterUrl) {
        this.loading = true;
        this.api.get(filterUrl).subscribe(data => {
          this.data = new MatTableDataSource(data.rows);
          this.selection = new SelectionModel(true, data);
          this.totalDataCount = data.count;
          this.loading = false;
        }, error => {
          if(error.status == 401) {
            localStorage.removeItem("cmsToken");
            this.router.navigate(['login']);
          }
        })
      }
    }
  }

  clearFilter() {
    if(this.filterGroup) {
      this.filterGroup.reset()
    }
    if(this.bulkEditGroup) {
      this.bulkEditGroup.reset()
    }
  }

  pageChange(event){
    this.page = event.pageIndex;
    this.pageSize = event.pageSize;
    this.filter();
  }

  clickRow(row) {
    //console.log(row);
    // console.log(this.selection)
  }

  selecitonChanged() {
    // console.log(this.selection);
  }

  isAllSelected() {
    const numSelected = this.selection?.selected.length;
    const numRows = this.pageSize;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.data.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection?.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  applyBulkEdit() {
    if(this.bulkEditGroup.value.parentSubject && !this.bulkEditGroup.value.subject) this.bulkEditGroup.value.subject = this.bulkEditGroup.value.parentSubject;
    delete this.bulkEditGroup.value['parentSubject'];
    Object.keys(this.bulkEditGroup.value).forEach((key) => (this.bulkEditGroup.value[key] == '') && delete this.bulkEditGroup.value[key]);
    if(this.selection.selected.length > 0) {
      let body = [];
      this.selection.selected.forEach( item => {
        body.push({ id: item.id, ...this.bulkEditGroup.value })
      });
      this.api.patch('/api/v1/' + this.slug + '/bulk-patch', { 'questions': body }).subscribe( data => {
        alert("Seçili kayıtlar güncellendi!");
      })
    } else {
      alert("Lütfen düzenlemek için seçim yapınız!")
    }
  }

  applyBulkDelete(): void {
    const questionsToDelete = [];
    this.selection.selected.forEach( item => {
      questionsToDelete.push(item.id);
    });
    const body = {questions: questionsToDelete};
    const r = confirm('Seçili soruları silmek istediğinize emin misiniz ?');
    if (r === true) {
      this.api.post('/api/v1/question/bulk-delete', body).subscribe( res => {
        console.log(res);
        alert('Kayıtlar Silindi!');
        this.filter();
      });
    } else {
      return;
    }
  }


}
