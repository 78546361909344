<ng-container>
    <mat-toolbar color="primary" >
        <mat-toolbar-row>
        <span>Toplu E-Posta Gönderme</span>
        <span class="toolbar-spacer"></span>
        <div class="toolbar-actions">
            
        </div>
        </mat-toolbar-row>
    </mat-toolbar>
    <mat-progress-bar mode="query" *ngIf="loading"></mat-progress-bar>
    <div class="list-container" *ngIf="mailScreen && !loading">
        <p>{{mailsToSend.length}} kullanıcıya mail gönderilecek.</p>
        <mat-form-field style="width:100%" appearance="outline">
            <mat-label>E-Posta Konusu</mat-label>
            <input [(ngModel)]="emailSubject" matInput>
        </mat-form-field>
        <quill-editor [styles]="{height: '200px'}" [(ngModel)]="emailBody" [placeholder]="'E-Posta metnini html olarak bu alana giriniz'"></quill-editor>
        <button class="btn-submit-form"  mat-raised-button color="primary" (click)="sendEmail()">GÖNDER</button>
    </div>
    <div class="list-container" *ngIf="!mailScreen">
        <button class="btn-submit-form" mat-raised-button color="primary" (click)="openMailScreen('all')">Tüm Kullanıcılara Mail Gönder</button> <button (click)="openMailScreen('some')" class="btn-submit-form" mat-raised-button color="primary">Seçili Kullanıcılara Mail Gönder</button>
        <br><br>
        <mat-form-field>
            <mat-label>Arama</mat-label>
            <input matInput (keyup)="applyFilter($event)">
        </mat-form-field>
        <div class="mat-elevation-z8">
            <mat-progress-bar mode="query" *ngIf="loading"></mat-progress-bar>
            <table mat-table [dataSource]="data" class="list-table" *ngIf="data">
                <!-- Created Column -->
                <!-- Checkbox Column -->
                <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef>
                    <mat-checkbox (change)="$event ? masterToggle() : null; selecitonChanged()"
                                    [checked]="selection.hasValue() && isAllSelected()"
                                    [indeterminate]="selection.hasValue() && !isAllSelected()"
                                    [aria-label]="checkboxLabel()">
                    </mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let row">
                    <mat-checkbox (click)="$event.stopPropagation(); selecitonChanged()"
                                    (change)="$event ? selection.toggle(row) : null"
                                    [checked]="selection.isSelected(row)"
                                    [aria-label]="checkboxLabel(row)">
                    </mat-checkbox>
                    </td>
                </ng-container>

                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef> Email </th>
                    <td mat-cell *matCellDef="let element">
                        <span [innerHTML]="element['email']"></span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef> Ad </th>
                    <td mat-cell *matCellDef="let element">
                        <span [innerHTML]="element['name']"></span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="surname">
                    <th mat-header-cell *matHeaderCellDef> Soyad </th>
                    <td mat-cell *matCellDef="let element">
                        <span [innerHTML]="element['surname']"></span>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="matColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: matColumns;"
  (click)="selection.toggle(row)">
            </table>
        </div>
    </div>
</ng-container>