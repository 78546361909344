import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styles: []
})
export class LoginComponent implements OnInit {
  form: FormGroup;
  emailError = 'Lütfen geçerli bir mail adresi giriniz!'
  passwordError = 'Lütfen geçerli bir şifre giriniz!'
  submitted = false;
  constructor(private fb: FormBuilder, private auth: AuthService, private router: Router) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      email: ['', [Validators.email, Validators.required]],
      password: ['', Validators.required]
    });
  }

  onSubmit() {
    if (this.form.valid) {
      this.submitted = true
      let body = this.form.value
      this.auth.login(body).subscribe( res => {
        if(res.token) {
          this.auth.token = res.token
          /*localStorage.setItem("cmsToken", res.token)*/
          this.router.navigate([''])
        }
      }, err => {
        this.form.controls['password'].setErrors({'incorrect': true})
        this.submitted = false
      })
    } else {
      this.submitted = false
    }
  }

}
