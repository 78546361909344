<mat-toolbar color="primary" >
    <mat-toolbar-row>
    <span>Önbellek Temizleme</span>
    <span class="toolbar-spacer"></span>
    <div class="toolbar-actions">
    </div>
    </mat-toolbar-row>
</mat-toolbar>
<div class="list-container">
    <h4>Hangi alandaki önbelleğin temizleneceğini seçiniz.</h4>
    <mat-form-field appearance="fill">
    <mat-label>Temizlenecek Kısım</mat-label>
    <mat-select [(ngModel)]="selectedType">
        <mat-option *ngFor="let type of cacheTypes" [value]="type.name">
        {{type.label}}
        </mat-option>
    </mat-select>
    </mat-form-field>
    <button class="btn-submit-form" mat-raised-button color="primary" (click)="clearCache()">Temizle</button>
</div>