const categoryModel = [
    {
        name: "name",
        label: "Başlık *",
        type: "text"
    },
    {
        name: "info",
        label: "Açıklama *",
        type: "text"
    },
    {
        name: "groupText",
        label: "Grup Soru Metni *",
        type: "text"
    },
    {
        name: "courseLink",
        label: "Kurs Linki",
        type: "text",
        required: false
    }
]

const categoryList = {
    title: "Tüm Kategoriler",
    canCreate: true,
    model: categoryModel,
    displayedColumns: [
        {
            name: "name",
            label: "Başlık"
        }
    ]
}

export { categoryList }