import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'senfonico-avm-panel';
  @ViewChild('sidenav') sidenav: MatSidenav;
  constructor(private router: Router) {
    router.events.subscribe((e:any) => {
      if(e instanceof NavigationEnd){
        if(e.url === '/login') {
          this.sidenav.close()
        } else {
          if(!this.sidenav.opened) this.sidenav.open()
        }
      }
    });
  }
  ngOnInit() {

  }  
}

