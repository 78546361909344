<ng-container *ngIf="settings">
    <mat-toolbar color="primary" >
        <mat-toolbar-row>
        <span>{{settings.title}}</span>
        <span class="toolbar-spacer"></span>
        <div class="toolbar-actions">
            <button *ngIf="slug === 'question'" [routerLink]="'/bulk-questions'" mat-button>Toplu Soru Girişi</button>
            <button [routerLink]="'/create/' + slug" mat-button>Yeni Oluştur</button>
        </div>
        </mat-toolbar-row>
    </mat-toolbar>
    <div class="list-container">
      <mat-form-field *ngIf="slug === 'question'" appearance="fill" style="margin-right: 5px">
        <mat-select [(ngModel)]="sorting" (selectionChange)="changeSorting()">
          <mat-option [value]="'new'">Önce En Yeni</mat-option>
          <mat-option [value]="'alphabetic'">Alfabetik Sıralama</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field *ngIf="slug === 'question'" appearance="fill" style="margin-right: 5px">
        <mat-select [(ngModel)]="filterQuiz" (selectionChange)="changeQuizFilter()">
          <mat-option [value]="''">Quize Göre Filtre</mat-option>
          <ng-container *ngFor="let quiz of quizzes">
            <mat-option [value]="quiz.id">{{quiz.name}}</mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
        <button mat-raised-button class="list-action" *ngIf="settings?.filter" (click)="clearFilter()">Filtreleri Temizle</button>
        <button mat-raised-button class="list-action" *ngIf="settings?.filter" (click)="detailedFilter = !detailedFilter" [class.mat-primary]="detailedFilter">Detaylı Filtre</button>
        <div class="filter" *ngIf="settings?.filter && detailedFilter">
            <form *ngIf="filterGroup" [formGroup]="filterGroup" (ngSubmit)="filter()" class="filter-form">
                <ng-container *ngFor="let filter of filters">
                    <mat-form-field appearance="fill" *ngIf="filter.type === 'ref'">
                        <mat-label>{{filter.label}}</mat-label>
                        <mat-select formControlName={{filter.name}}>
                            <mat-option [value]="">Hepsi</mat-option>
                            <mat-option *ngFor="let value of filter.options" [value]="value.id">
                            {{value.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="fill" *ngIf="filter.type === 'select'">
                        <mat-label>{{filter.label}}</mat-label>
                        <mat-select formControlName={{filter.name}}>
                            <mat-option [value]="">Hepsi</mat-option>
                            <mat-option *ngFor="let value of filter.options" [value]="value.name">
                            {{value.label}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="fill" *ngIf="filter.type === 'html' || filter.type === 'text'">
                        <mat-label>{{filter.label}}</mat-label>
                        <input formControlName={{filter.name}} matInput>
                    </mat-form-field>
                  <mat-form-field appearance="fill" *ngIf="filter.type === 'number'">
                    <mat-label>{{filter.label}}</mat-label>
                    <input formControlName={{filter.name}} matInput type="number">
                  </mat-form-field>
                </ng-container>
                <ng-container *ngIf="slug === 'question'">
                  <div class="question-year">
                    <mat-form-field appearance="fill">
                      <mat-label>Yıl Büyüktür Küçüktür</mat-label>
                      <mat-select (selectionChange)="yearChange($event)">
                        <mat-option [value]="">Seçilmemiş</mat-option>
                        <mat-option [value]="'greaterThan'">Büyüktür</mat-option>
                        <mat-option [value]="'lessThan'">Küçüktür</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </ng-container>
                <button class="btn-submit-form filter-btn" mat-raised-button color="primary">Filtrele</button>
            </form>
        </div>
        <button mat-raised-button class="list-action" *ngIf="settings?.bulkEdit" (click)="bulkEdit = !bulkEdit" [class.mat-primary]="bulkEdit">Toplu Düzenle</button>
        <button mat-raised-button color="warn" class="list-action" *ngIf="slug === 'question'" (click)="applyBulkDelete()">Toplu Sil</button>
        <div class="filter" *ngIf="settings?.bulkEdit && bulkEdit">
            <form *ngIf="filterGroup" [formGroup]="bulkEditGroup" (ngSubmit)="applyBulkEdit()" class="filter-form">
                <ng-container *ngFor="let filter of bulkEdits">
                    <mat-form-field appearance="fill" *ngIf="filter.type === 'ref' && filter.name !== 'subject'">
                        <mat-label>{{filter.label}}</mat-label>
                        <mat-select formControlName={{filter.name}}>
                            <mat-option [value]="">Seçiniz</mat-option>
                            <mat-option *ngFor="let value of filter.options" [value]="value.id">
                            {{value.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="fill" *ngIf="filter.type === 'ref' && filter.name === 'subject'">
                        <mat-label>Ana Konu</mat-label>
                        <mat-select formControlName="parentSubject">
                            <mat-option [value]="">Seçiniz</mat-option>
                            <ng-container *ngFor="let value of filter.options">
                                <mat-option  [value]="value.id" *ngIf="value.parent === null">
                                    {{value.name}}
                                    </mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="fill" *ngIf="filter.type === 'ref' && filter.name === 'subject' && this.bulkEditGroup.value.parentSubject">
                        <mat-label>Konu</mat-label>
                        <mat-select formControlName="subject">
                            <mat-option [value]="">Seçiniz</mat-option>
                            <ng-container *ngFor="let value of filter.options">
                                <mat-option  [value]="value.id" *ngIf="value.parent === this.bulkEditGroup.value.parentSubject">
                                    {{value.name}}
                                    </mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="fill" *ngIf="filter.type === 'select'">
                        <mat-label>{{filter.label}}</mat-label>
                        <mat-select formControlName={{filter.name}}>
                            <mat-option [value]="">Seçiniz</mat-option>
                            <mat-option *ngFor="let value of filter.options" [value]="value.name">
                            {{value.label}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                  <mat-form-field appearance="fill" *ngIf="filter.type === 'number'">
                    <mat-label>{{filter.label}}</mat-label>
                    <input type="number" formControlName={{filter.name}} matInput>
                  </mat-form-field>
                    <mat-form-field appearance="fill" *ngIf="filter.type === 'html' || filter.type === 'text'">
                        <mat-label>{{filter.label}}</mat-label>
                        <input formControlName={{filter.name}} matInput>
                    </mat-form-field>
                  <mat-form-field appearance="fill" *ngIf="filter.type === 'number'">
                    <mat-label>{{filter.label}}</mat-label>
                    <input formControlName={{filter.name}} matInput type="number">
                  </mat-form-field>
                </ng-container>
                <button class="btn-submit-form filter-btn" mat-raised-button color="primary">Düzenle</button>
            </form>
        </div>
        <mat-form-field *ngIf="!settings?.filter">
            <mat-label>Arama</mat-label>
            <input matInput (keyup)="applyFilter($event)">
        </mat-form-field>
            <div class="mat-elevation-z8">
                <mat-progress-bar mode="query" *ngIf="loading"></mat-progress-bar>
                <table mat-table [dataSource]="data" class="list-table" *ngIf="data && slug !== 'question'">
                    <!-- Created Column -->
                    <ng-container matColumnDef="{{column.name}}" *ngFor="let column of displayedColumns">
                        <th mat-header-cell *matHeaderCellDef> {{column.label}} </th>
                        <td mat-cell *matCellDef="let element">
                            <span *ngIf="!column.nested && column.type !== 'html'">{{element[column.name]}}</span>
                            <span *ngIf="!column.nested && column.type === 'html'" [innerHTML]="element[column.name]"></span>
                            <span *ngIf="column.nested && element[column.name]">{{element[column.name][column.nested]}}</span>
                            <span *ngIf="column.nested && !element[column.name]"></span>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="matColumns"></tr>
                    <tr [routerLink]="'/edit/' + slug + '/' + row.id" mat-row *matRowDef="let row; columns: matColumns;"></tr>
                </table>
                <table mat-table [dataSource]="data" class="list-table" *ngIf="data && slug === 'question'">
                    <!-- Created Column -->
                    <!-- Checkbox Column -->
                    <ng-container matColumnDef="select">
                        <th mat-header-cell *matHeaderCellDef>
                        <mat-checkbox (change)="$event ? masterToggle() : null; selecitonChanged()"
                                        [checked]="selection.hasValue() && isAllSelected()"
                                        [indeterminate]="selection.hasValue() && !isAllSelected()"
                                        [aria-label]="checkboxLabel()">
                        </mat-checkbox>
                        </th>
                        <td mat-cell *matCellDef="let row">
                        <mat-checkbox (click)="$event.stopPropagation(); selecitonChanged()"
                                        (change)="$event ? selection.toggle(row) : null"
                                        [checked]="selection.isSelected(row)"
                                        [aria-label]="checkboxLabel(row)">
                        </mat-checkbox>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="questionText">
                        <th mat-header-cell *matHeaderCellDef> Soru </th>
                        <td mat-cell *matCellDef="let element">
                            <span [innerHTML]="element['questionText']"></span><br><b>Yıl:</b> {{element['year']}}
                          || <b>Sınav Türü:</b> {{getExamTypeById(element['examType'])}}
                          || <b>Kategori:</b> {{getQuestionCategoryById(element['category'])}}
                          <ng-container *ngIf="getSubjectParent(element['subject'])">|| <b>Ana Konu:</b> {{getSubjectParent(element['subject'])}}</ng-container>
                          || <b>Konu:</b> {{getSubjectById(element['subject'])}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="edit">
                        <th mat-header-cell *matHeaderCellDef> Düzenle </th>
                        <td mat-cell *matCellDef="let element">
                            <span class="edit-icon" [routerLink]="'/edit/' + slug + '/' + element['id']"><mat-icon>create</mat-icon></span>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="matColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: matColumns;"
      (click)="selection.toggle(row)">
                </table>
                <mat-paginator (page)="pageChange($event)" [length]="totalDataCount" [pageSizeOptions]="[100, 10, 25, 50]" showFirstLastButtons></mat-paginator>
            </div>
    </div>
</ng-container>
